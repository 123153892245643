<template>
  <layout>
    <breadcrumb title="Product Details" current="Product Details" />

    <!-- Start product details area -->
    <div class="product-details-area rn-section-gapTop">
      <div class="container">
        <div class="row g-5">
          <!-- Product image area Start -->
          <div class="col-lg-7 col-md-12 col-sm-12">
            <div class="product-tab-wrapper rbt-sticky-top-adjust">
              <div class="pd-tab-inner">
                <div
                  class="nav rn-pd-nav rn-pd-rt-content nav-pills"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <!-- <button>
                    <span class="rn-pd-sm-thumbnail">
                      <img
                        width="164"
                        height="164"
                        :src="`${url}/${found.file.s3Uri}`"
                        alt="Nft_Profile"
                        v-if="found"
                      />
                    </span>
                  </button> -->
                </div>
                <div class="tab-content rn-pd-content" id="v-pills-tabContent">
                  <div>
                    <div class="rn-pd-thumbnail">
                      <img
                        :src="`${url}/${found.file.s3Uri}`"
                        alt="Nft_Profile"
                        v-if="found"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Product image area end -->

          <div class="col-lg-5 col-md-12 col-sm-12 mt_md--50 mt_sm--60">
            <div class="rn-pd-content-area">
              <div class="pd-title-area">
                <h4 class="title" v-if="found">{{ found.name }}</h4>

                <div class="pd-react-area">
                  <!-- <div class="heart-count">
                                        <i class="feather-heart"/>
                                        <span>{{ product.reacted }}</span>
                                    </div> -->
                  <!-- <div class="count">
                    <div class="share-btn share-btn-activation dropdown">
                      <button
                        class="icon"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i class="feather-more-horizontal" />
                      </button>
                      <div
                        class="share-btn-setting dropdown-menu dropdown-menu-end"
                      >
                        <button
                          type="button"
                          class="btn-setting-text share-text"
                          data-bs-toggle="modal"
                          data-bs-target="#shareModal"
                        >
                          Share
                        </button>
                        <button
                          type="button"
                          class="btn-setting-text report-text"
                          data-bs-toggle="modal"
                          data-bs-target="#reportModal"
                        >
                          Report
                        </button>
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>
              <!-- <span class="bid">
                                Height bid <span class="price">{{ product.lastBid }}</span>
                            </span> -->
              <div class="price" v-if="isListed">
                <div class="logo-thumbnail logo-custom-css">
                  <img
                    :src="require(`@/assets/images/logo/proximax-logo.png`)"
                    alt="nft-logo"
                  />
                </div>
                <h4 class="title" v-if="isListed">{{ price }}</h4>
              </div>

              <!-- <h6 class="title-name">#22 Portal , Info bellow</h6> -->
              <!-- <div class="catagory-collection">
                <div class="catagory">
                  <span>
                    Catagory <span class="color-body">10% royalties</span>
                  </span>
                  <div class="top-seller-inner-one">
                    <div class="top-seller-wrapper">
                      <div class="thumbnail">
                        <router-link to="#">
                          <img
                            :src="
                              require(`@/assets/images/client/client-1.png`)
                            "
                            alt="Nft_Profile"
                          />
                        </router-link>
                      </div>
                      <div class="top-seller-content" v-if="found">
                        <router-link :to="`/profile/${found.owner.walletPublicKey}`">
                          <h6 class="name" v-if="nft">
                            {{ found.owner.username }}
                          </h6>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="collection">
                  <span>Collections</span>
                  <div class="top-seller-inner-one">
                    <div class="top-seller-wrapper">
                      <div class="thumbnail">
                        <router-link to="#">
                          <img
                            :src="
                              require(`@/assets/images/client/client-2.png`)
                            "
                            alt="Nft_Profile"
                          />
                        </router-link>
                      </div>
                      <div class="top-seller-content" v-if="found">
                        <router-link :to="`/profile/${found.owner.walletPublicKey}`">
                          <h6 class="name">Brodband</h6>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="button-div">
                <button
                  class="btn btn-primary-alta"
                  data-bs-toggle="modal"
                  data-bs-target="#uploadModal5"
                  @click="generate()"
                  v-if="state == 3"
                >
                  List Nft
                </button>
                <button
                  class="btn btn-primary-alta"
                  @click="verifyNft()"
                  v-if="state == 2"
                >
                  Verify NFT
                </button>
                <button
                  class="btn btn-primary-alta"
                  data-bs-toggle="modal"
                  data-bs-target="#uploadModal5"
                  @click="generate1()"
                  v-if="state == 4"
                >
                  Edit List NFT
                </button>
                <button
                  class="btn btn-primary-alta"
                  v-if="state == 4"
                  @click="cancelListing()"
                >
                  Cancel Listing
                </button>
                <button
                  class="btn btn-primary-alta "
                  v-if="state == 5"
                  @click="purchaseNft()"
                >
                  Buy Nft
                </button>
              </div>
              <!--Modal-->
              <div
                class="rn-popup-modal upload-modal-wrapper modal fade"
                id="uploadModal5"
                tabindex="-1"
                aria-hidden="true"
              >
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i class="feather-x" />
                </button>
                <div
                  class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
                >
                  <div class="modal-content share-wrapper">
                    <div class="modal-body">
                      <edit-listing v-if="state == 4" :nftId="props.id" />
                      <listing-form v-if="state == 3" :nftId="props.id" />
                    </div>
                  </div>
                </div>
              </div>
              <!--End Modal-->
              <div class="rn-bid-details">
                <div class="tab-wrapper-one">
                  <nav class="tab-button-one">
                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                      <button
                        class="nav-link active"
                        id="nav-profile-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-profile"
                        type="button"
                        role="tab"
                        aria-controls="nav-profile"
                        aria-selected="true"
                      >
                        Details
                      </button>
                      <button
                        class="nav-link"
                        id="nav-contact-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-contact"
                        type="button"
                        role="tab"
                        aria-controls="nav-contact"
                        aria-selected="false"
                      >
                        History
                      </button>
                    </div>
                  </nav>
                  <div class="tab-content rn-bid-content" id="nav-tabContent">
                    <div
                      class="tab-pane fade show active"
                      id="nav-home"
                      role="tabpanel"
                      aria-labelledby="nav-home-tab"
                    >

                      
                      <!-- Single creator -->
                    </div>
                    <div
                      class="tab-pane fade show active"
                      id="nav-profile"
                      role="tabpanel"
                      aria-labelledby="nav-profile-tab"
                    >
                      <div class="rn-pd-bd-wrapper">
                        <div class="top-seller-inner-one">
                          <h6 class="name-title">Owner</h6>
                          <div class="top-seller-wrapper">
                            <div class="thumbnail" v-if="found">
                              <router-link :to="`/profile/${found.owner.walletPublicKey}`">
                                <img v-if="pfp" :src="pfp" alt="Nft_Profile"/>
                                <img v-else
                                  :src="
                                    require(`@/assets/images/client/blankPfp.png`)
                                  "
                                  alt="Nft_Profile"
                                />
                              </router-link>
                            </div>
                            <div class="top-seller-content" v-if="found">
                              <router-link :to="`/profile/${found.owner.walletPublicKey}`">
                                <h6 class="name" v-if="found">
                                  {{ found.owner.username }}
                                </h6>
                              </router-link>
                            </div>
                          </div>
                        </div>
                        <div class="rn-pd-sm-property-wrapper">
                          <h6 class="pd-property-title">Description</h6>
                          <div class="property-wrapper" v-if="found">
                            <span class="color-white value positions">{{
                              descriptions
                            }}</span>
                          </div>
                        </div>
                        <div class="rn-pd-sm-property-wrapper">
                          <h6 class="pd-property-title">Property</h6>
                          <div class="property-wrapper">
                            <!-- single property -->
                            <div
                              class="pd-property-inner"
                              v-for="(property, index) of propertyKeys"
                              :key="index"
                            >
                              <span class="color-body type">{{
                                property.toLocaleUpperCase()
                              }}</span>
                              <span class="color-white value">{{
                                propertyValues[index]
                              }}</span>
                            </div>
                            <!-- single property End -->
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="nav-contact"
                      role="tabpanel"
                      aria-labelledby="nav-contact-tab"
                    >
                      <!-- Single creator -->
                      <div
                        class="top-seller-inner-one"
                        v-for="(property, index) of history"
                        :key="index"
                      >
                        <div class="top-seller-wrapper">
                          <div class="thumbnail" v-if="found">
                            <router-link :to="`/profile/${property.buyer.walletPublicKey}`"
                              ><img v-if="!property.buyer.profilePic"
                                :src="
                                  require(`@/assets/images/client/blankPfp.png`)
                                "
                                alt="Nft_Profile"
                              />
                            
                                <img v-else
                                  :src="`${store.imageUrl}/${property.buyer.profilePic.s3Uri}`"
                                  alt="Nft_Profile"
                                />
                              
                          </router-link>
                          </div>
                          <div class="top-seller-content" v-if="found">
                            <span>
                              <router-link class="profilename" :to="`/profile/${property.buyer.walletPublicKey}`">{{
                                property.buyer.username
                              }}</router-link>
                            </span>
                            <span class="count-number">{{
                              formatTime(property.purchaseTime)
                            }}</span>
                          </div>
                        </div>
                      </div>
                      <!-- Single creator -->

                      <!-- Single creator -->
                      
                      <!-- Single creator -->

                      <!-- Single creator -->
                      
                      <!-- Single creator -->

                      <!-- Single creator -->
                      
                      <!-- Single creator -->

                      <!-- Single creator -->
                      
                      <!-- Single creator -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End product details area -->

    <!-- Recent view area Start -->
    <!-- <div class="rn-new-items rn-section-gapTop">
            <div class="container">
                <div class="row mb--30 align-items-center">
                    <div class="col-12">
                        <h3 class="title mb--0">Recent View</h3>
                    </div>
                </div>
                <div class="row g-5">
                    <div class="col-5 col-lg-4 col-md-6 col-sm-6 col-12"
                         v-for="(item, index) in recentViewItems"
                         :key="`newest-item-${index}`">
                        <product-card
                            :product-date="item"
                            product-style-class="no-overlay"
                        />
                    </div>
                </div>
            </div>
        </div> -->
    <!-- Recent view area End -->

    <!-- Related item area Start -->
    <!-- <div class="rn-new-items rn-section-gapTop">
            <div class="container">
                <div class="row mb--30 align-items-center">
                    <div class="col-12">
                        <h3 class="title mb--0">Related Item</h3>
                    </div>
                </div>
                <div class="row g-5">
                    <div class="col-5 col-lg-4 col-md-6 col-sm-6 col-12"
                         v-for="(item, index) in relatedItems"
                         :key="`newest-item-${index}`">
                        <product-card
                            :product-date="item"
                            product-style-class="no-overlay"
                        />
                    </div>
                </div>
            </div>
        </div> -->
    <!-- Related item area End -->

    <!-- <share-modal/>
        
        <report-modal/>

        <placebid-modal/> -->
  </layout>
</template>
<script setup>
import { useRouter } from "vue-router";
import { ref, watch, computed, getCurrentInstance, onMounted } from "vue";
import { backend } from "../store/storeUrl";
import { useToast } from "primevue/usetoast";
import axios from "axios";
import ListingForm from "../components/form/ListingForm.vue";
import editListing from "../components/form/EditListing.vue";
import { store } from "../store/store";

const props = defineProps({
  id: String,
});
const url = backend.imageUrl;
const router = useRouter();
const property = ref();
const publicKey = ref("");
const toast = useToast();
const found = ref(null);
const state = ref(0);
const descriptions = ref("");
const history = ref([]);
const isListed = ref(false)
const price = ref("");
const token = ref("");
const listingId = ref("");
const pfp = ref(null);
token.value = sessionStorage.getItem("accessToken");
publicKey.value = sessionStorage.getItem("userPublicKey");
const config = {
  headers: {
    authorization: "Bearer " + token.value,
  },
};


//`${backend.url}/nft/id/${props.id}`
const displayPfp = () => {
  axios.get(`${backend.url}/nft/id/${props.id}`).then((res)=>{
    let profImg = res.data.owner.profilePic == null? null: `${store.imageUrl}/${res.data.owner.profilePic.s3Uri}`
    if(profImg){
        pfp.value = profImg;
    }
    }).catch(e=>{
        toast.add({severity:'error', summary: "Error", detail:"error", group: 'br', life: 3000});
    })
}

displayPfp()

const formatTime = (timestamp) => {
  let date = new Date(timestamp);
  const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"];
  return monthNames[date.getMonth()] +  ' ' + date.getDate() + ', '+ date.getFullYear() + " at " + date.getHours() + ":" + date.getMinutes();//prints expected format.
}
const internalInstance = getCurrentInstance();
const emitter = internalInstance.appContext.config.globalProperties.emitter;

const fetchSessionStorage = () => {
  publicKey.value = sessionStorage.getItem("userPublicKey");
};


const displayPrice = (upper, lower) => {
  if (!lower) {
    return upper;
  }
  return parseFloat(upper.toString() + "." + lower.toString());
};


//state == 0 cant do anything to nft since it doesnt belong to it
//state == 2 display verify nft
//state == 3 if nft is not listed the display list button
//state == 4 if nft is listed display cancel and edit listing
//state == 5 if it doewsnt belong to the user then it will display buy nft button
//state == 6 if nft is verified but still in process then it display the verify in progress
const created = async() => {
  fetchSessionStorage()
  try {
    
    let nftDetails = await axios.get(`${backend.url}/nft/id/${props.id}`)
    let listed = await axios.get(`${backend.url}/directListings/isListed/${props.id}`)
    
    found.value = nftDetails.data
    if(!found.value){ //if nft is not found
      router.push("/");
      return;
    }
    descriptions.value = found.value.description
    property.value = found.value.properties
   
    if(listed.data){
      listingId.value = listed.data.id
      isListed.value = true;
      price.value = displayPrice(listed.data.minPriceUpper,listed.data.minPriceLower)
    }

    if(!loginState.value){ //if login state is false 

      if(!isListed.value){ //if not listed it will return
        return;
      }
      state.value = 5 //show buy button
      return;
    }

    if(found.value.owner.walletPublicKey !== publicKey.value){ //checking if owner of nft is the same publickey as the user now 

      if(!isListed.value){
        return;
      }
      state.value = 5 // show buy button 
      return;
    }
    //owner code 
    if(!isListed.value){
      if(found.value.blockchainStatus === 0){
        state.value = 2
        return;
      }
      if(found.value.blockchainStatus === 1){
        state.value = 6
        return;
      }
      state.value = 3
      return
    }

    state.value = 4
  } catch (error) {
    toast.add({severity: "error",
        summary: "503",
        detail: "Failed to fetch data",
        group: "br",
        life: 3000,})
  }
}


const cancelListing = () => {
  axios.post(
      `${backend.url}/directListings/cancel`,
      {
        listingId: listingId.value,
      },
      config
    )
    .then((res) => {
      toast.add({
        severity: "success",
        summary: "NFT is delisted",
        detail: "",
        group: "br",
        life: 3000,
      });
      router.push("/profile/" + publicKey.value);
    })
    .catch((e) => {
      toast.add({
        severity: "error",
        summary: e.response.statusText,
        detail: e.response.data.message,
        group: "br",
        life: 3000,
      });
    });
};

const attempt = ref(false);

const purchaseNft = () => {
  if (!loginState.value) {
    toast.add({
      severity: "error",
      summary: "Not Connected",
      detail: "Please connect wallet and register account",
      group: "br",
      life: 3000,
    });
    router.push(`/connect`)
    return;
  }
  router.push(`/purchase/${props.id}`);
};

const historyNft = () => {
  axios.get(`${backend.url}/purchase/history/${props.id}`).then((res) => {
    history.value = res.data;
     })
  };
  

//created
onMounted(async () => {
  await created()
  historyNft();
});

const generate = () => {
  emitter.emit("list clicked");
};

const generate1 = () => {
  emitter.emit("edit clicked");
};

const verifyNft = () => {
  router.push(`/product/verify/${props.id}`);
};

//display property
const propertyKeys = computed(() => {
  if (!property.value) {
    return [];
  }
  let properties = Object.keys(property.value).map((key) => {
    return key;
  });
  return properties;
});
const propertyValues = computed(() => {
  if (!property.value) {
    return [];
  }
  let properties = Object.keys(property.value).map((key) => {
    return property.value[key];
  });
  return properties;
});
</script>

<script>
import Layout from "@/components/layouts/Layout";
import Breadcrumb from "@/components/breadcrumb/Breadcrumb";
import ProductCard from "@/components/product/ProductCard";
import ShareModal from "@/components/modal/ShareModal";
import ReportModal from "@/components/modal/ReportModal";
import Countdown from "@/components/product/Countdown";
import PlacebidModal from "@/components/modal/PlacebidModal";
import ProductMixin from "@/mixins/ProductMixin";
import { loginState } from "../auth/auth";

export default {
  name: "ProductDetails",
  components: {
    PlacebidModal,
    Countdown,
    ReportModal,
    ShareModal,
    ProductCard,
    Breadcrumb,
    Layout,
  },
  mixins: [ProductMixin],
  data() {
    return {
      product: {},
      activeTabIndex: 0,
      recentViewItems: [
        {
          id: 1,
          productImage: require(`@/assets/images/portfolio/portfolio-01.jpg`),
          authors: [
            {
              id: 1,
              name: "Jone lee",
              image: require(`@/assets/images/client/client-1.png`),
            },
            {
              id: 2,
              name: "Jone lee",
              image: require(`@/assets/images/client/client-2.png`),
            },
            {
              id: 3,
              name: "Nisat Tara",
              image: require(`@/assets/images/client/client-3.png`),
            },
          ],
          biddingAmount: "9",
          productName: "Preatent",
          latestBid: "Highest bid 1/20",
          lastBid: "0.244wETH",
          reacted: "322",
        },
        {
          id: 2,
          productImage: require(`@/assets/images/portfolio/portfolio-02.jpg`),
          authors: [
            {
              id: 4,
              name: "Jone lee",
              image: require(`@/assets/images/client/client-4.png`),
            },
            {
              id: 5,
              name: "Nira Ara",
              image: require(`@/assets/images/client/client-5.png`),
            },
            {
              id: 6,
              name: "Fatima Afrafy",
              image: require(`@/assets/images/client/client-6.png`),
            },
          ],
          biddingAmount: "10",
          productName: "Diamond Dog",
          latestBid: "Highest bid 5/11",
          lastBid: "0.892wETH",
          reacted: "420",
        },
        {
          id: 3,
          productImage: require(`@/assets/images/portfolio/portfolio-03.jpg`),
          authors: [
            {
              id: 1,
              name: "Jone lee",
              image: require(`@/assets/images/client/client-1.png`),
            },
            {
              id: 8,
              name: "Janin Ara",
              image: require(`@/assets/images/client/client-8.png`),
            },
            {
              id: 9,
              name: "Atif Islam",
              image: require(`@/assets/images/client/client-9.png`),
            },
          ],
          biddingAmount: "10",
          productName: "OrBid6",
          latestBid: "Highest bid 2/31",
          lastBid: "0.2128wETH",
          reacted: "12",
        },
        {
          id: 4,
          productImage: require(`@/assets/images/portfolio/portfolio-04.jpg`),
          authors: [
            {
              id: 1,
              name: "Jone lee",
              image: require(`@/assets/images/client/client-1.png`),
            },
            {
              id: 3,
              name: "Jone lee",
              image: require(`@/assets/images/client/client-3.png`),
            },
            {
              id: 5,
              name: "Jone lee",
              image: require(`@/assets/images/client/client-5.png`),
            },
          ],
          biddingAmount: "8",
          productName: "Morgan11",
          latestBid: "Highest bid 3/16",
          lastBid: "0.265wETH",
          reacted: "20",
        },
        {
          id: 5,
          productImage: require(`@/assets/images/portfolio/portfolio-05.jpg`),
          authors: [
            {
              id: 2,
              name: "Jone lee",
              image: require(`@/assets/images/client/client-2.png`),
            },
            {
              id: 7,
              name: "Jone lee",
              image: require(`@/assets/images/client/client-7.png`),
            },
            {
              id: 9,
              name: "Jone lee",
              image: require(`@/assets/images/client/client-9.png`),
            },
          ],
          biddingAmount: "15",
          productName: "mAtal8",
          latestBid: "Highest bid 6/50",
          lastBid: "0.244wETH",
          reacted: "205",
        },
      ],
      relatedItems: [
        {
          id: 1,
          productImage: require(`@/assets/images/portfolio/portfolio-01.jpg`),
          authors: [
            {
              id: 1,
              name: "Jone lee",
              image: require(`@/assets/images/client/client-1.png`),
            },
            {
              id: 2,
              name: "Jone lee",
              image: require(`@/assets/images/client/client-2.png`),
            },
            {
              id: 3,
              name: "Nisat Tara",
              image: require(`@/assets/images/client/client-3.png`),
            },
          ],
          biddingAmount: "9",
          productName: "Preatent",
          latestBid: "Highest bid 1/20",
          lastBid: "0.244wETH",
          reacted: "322",
        },
        {
          id: 2,
          productImage: require(`@/assets/images/portfolio/portfolio-02.jpg`),
          authors: [
            {
              id: 4,
              name: "Jone lee",
              image: require(`@/assets/images/client/client-4.png`),
            },
            {
              id: 5,
              name: "Nira Ara",
              image: require(`@/assets/images/client/client-5.png`),
            },
            {
              id: 6,
              name: "Fatima Afrafy",
              image: require(`@/assets/images/client/client-6.png`),
            },
          ],
          biddingAmount: "10",
          productName: "Diamond Dog",
          latestBid: "Highest bid 5/11",
          lastBid: "0.892wETH",
          reacted: "420",
        },
        {
          id: 3,
          productImage: require(`@/assets/images/portfolio/portfolio-03.jpg`),
          authors: [
            {
              id: 1,
              name: "Jone lee",
              image: require(`@/assets/images/client/client-1.png`),
            },
            {
              id: 8,
              name: "Janin Ara",
              image: require(`@/assets/images/client/client-8.png`),
            },
            {
              id: 9,
              name: "Atif Islam",
              image: require(`@/assets/images/client/client-9.png`),
            },
          ],
          biddingAmount: "10",
          productName: "OrBid6",
          latestBid: "Highest bid 2/31",
          lastBid: "0.2128wETH",
          reacted: "12",
        },
        {
          id: 4,
          productImage: require(`@/assets/images/portfolio/portfolio-04.jpg`),
          authors: [
            {
              id: 1,
              name: "Jone lee",
              image: require(`@/assets/images/client/client-1.png`),
            },
            {
              id: 3,
              name: "Jone lee",
              image: require(`@/assets/images/client/client-3.png`),
            },
            {
              id: 5,
              name: "Jone lee",
              image: require(`@/assets/images/client/client-5.png`),
            },
          ],
          biddingAmount: "8",
          productName: "Morgan11",
          latestBid: "Highest bid 3/16",
          lastBid: "0.265wETH",
          reacted: "20",
        },
        {
          id: 5,
          productImage: require(`@/assets/images/portfolio/portfolio-05.jpg`),
          authors: [
            {
              id: 2,
              name: "Jone lee",
              image: require(`@/assets/images/client/client-2.png`),
            },
            {
              id: 7,
              name: "Jone lee",
              image: require(`@/assets/images/client/client-7.png`),
            },
            {
              id: 9,
              name: "Jone lee",
              image: require(`@/assets/images/client/client-9.png`),
            },
          ],
          biddingAmount: "15",
          productName: "mAtal8",
          latestBid: "Highest bid 6/50",
          lastBid: "0.244wETH",
          reacted: "205",
        },
      ],
    };
  },
  methods: {
    // getProduct(productId) {
    //     this.product = this.products.find(item => item.id == productId);
    // }
  },
  created() {
    // this.getProduct(this.id);
  },
};
</script>
<style scoped>
.price {
  display: flex;
}

.button-div {
  display: flex;
  justify-content: space-around;
}

.buybutton{
  margin-left: 475px;
}

.profilename{
  justify-content: end;
}
.positions {
  padding-left: 7px;
  padding-top: 7px;
}

.listed{
  padding-right: 475px;
  font-size: 15px;
}
</style>
