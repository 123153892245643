import {reactive} from 'vue'
import config from '../../public/config/config.json'

export const store = {

    networkType: config.network.type,
    testApiEndPoint: config.apiNodes[0],
    httpPort: config.httpPort,
    generationHash: config.generationHash,
    backendUrl: config.backendUrl,
    imageUrl: config.imageUrl,
    xpx:{
        name: config.network.currency.xpx.name,
        namespace: config.network.currency.xpx.namespace,
        assetId: config.network.currency.xpx.assetId,
        namespaceId: config.network.currency.xpx.namespaceId,
        divisibility: config.network.currency.xpx.divisibility, 
    },
    metx:{
        name: config.network.currency.metx.name,
        namespace: config.network.currency.metx.namespace,
        assetId: config.network.currency.metx.assetId,
        namespaceId: config.network.currency.metx.namespaceId,
        divisibility: config.network.currency.metx.divisibility,
    },
    lockDuration: config.lockDuration
    
        // endPoint: "https://api-2.testnet2.xpxsirius.io"
}

