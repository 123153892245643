<template>
  <Layout>
    <div style="display: flex; justify-content: center">
      <div v-if="address === ''">
        <img
          :src="require(`@/assets/images/connect/connect-01.jpg`)"
          alt="Nft_Profile"
        />
      </div>
      <div v-else>
        <div class="wallet">
          <div>
            <h2>Your Address</h2>
          </div>
          <div class="walletDiv">
            <div class="walletCopy">{{ address }}</div>
            <img
              @click="copyAddress"
              src="../assets/images/icons/copy-icon.svg"
              style="width: 5%"
            />
          </div>
          <div class="containerAsset" v-for="(asset, index) in assets">
            <div class="assetDiv">
              <div class="assetName">
                <img
                  :src="require(`@/assets/images/logo/${asset.image}`)"
                  :style="`${asset.style}`"
                />
                <div style="margin-left: 10px">{{ asset.name }}</div>
              </div>
              <div class="assetValue">
                <div v-if="index === 0">{{ xpxDisplay }}</div>
                <div v-else>{{ metxDisplay }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script setup>
import Layout from "../components/layouts/Layout.vue";
import { PublicAccount, AccountHttp, Address } from "tsjs-xpx-chain-sdk";
import { getCurrentInstance, ref, onMounted, computed, inject } from "vue";
import { store } from "../store/store";
let network = store.networkType;
const internalInstance = getCurrentInstance();
const emitter = internalInstance.appContext.config.globalProperties.emitter;

const toCurrencyFormat = (value, divisibility) => {
  const exactValue = value;
  return new Intl.NumberFormat("en", {
    maximumFractionDigits: divisibility,
  }).format(exactValue);
};

const xpxBalance = ref(0);
const xpxDisplay = computed(() => {
  return toCurrencyFormat(xpxBalance.value, store.xpx.divisibility);
});
const metxBalance = ref(0);
const metxDisplay = computed(() => {
  return toCurrencyFormat(metxBalance.value, store.metx.divisibility);
});
const address = ref("");
const assets = ref([
  {
    image: "proximax-logo.png",
    name: "XPX",
    style: "width: 30px",
  },
  {
    image: "metx.png",
    name: "METX",
    style: "width: 30px",
  },
]);

const fetchSessionStorage = () => {
  const publicKey = sessionStorage.getItem("userPublicKey");
  if (publicKey != null) {
    address.value = PublicAccount.createFromPublicKey(
      publicKey,
      network
    ).address.pretty();
    getBalanceAsset();
  } else {
    address.value = "";
  }
};

const getBalanceAsset = async () => {
  let API_Endpoint = store.testApiEndPoint;
  let accountHttp = new AccountHttp(API_Endpoint);
  const publicKey = sessionStorage.getItem("userPublicKey");
  if (publicKey === null) {
    return;
  }
  let address = Address.createFromPublicKey(publicKey, network);

  let accountInfo = await accountHttp.getAccountInfo(address).toPromise();
  let xpxAsset = accountInfo.mosaics.find(
    (asset) => asset.id.toHex().toLowerCase() === store.xpx.assetId
  );
  let metxAsset = accountInfo.mosaics.find(
    (asset) => asset.id.toHex().toLowerCase() === store.metx.assetId
  );
  if (xpxAsset) {
    xpxBalance.value = xpxAsset.amount.compact() / Math.pow(10, 6);
  }
  if (metxAsset) {
    metxBalance.value = metxAsset.amount.compact() / Math.pow(10, 6);
  }
};

onMounted(() => {
  fetchSessionStorage();
});

const copyAddress = () => {
    navigator.clipboard.writeText(address.value)
    alert("copied")
}
</script>
<style scoped>
.wallet {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.walletDiv{
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: transparent;
    color: grey;
    outline-style: solid;
    outline-color: white;
    padding: 2px;
    border-radius: 4px;
    height: 50px;
    width: 500px;
}

.containerAsset{
  width: 100%;
}

.assetDiv{
   display: flex;
   justify-content: space-between;
   width: 100%;
    align-items: center;
    margin-top:30px;
    margin-bottom: 10px;
    font-size: 30px;
}

.assetName{
    display:flex;
    align-items: center;
}

.assetValue{
    font-weight: bold;
}

.containerShowWallet{
   background-color: #24243557;
}
</style>

