<template>
  <div class="container">
      <div class="form-group">
        <label for="exampleInputEmail1">Input Price</label>
        <NumberInput v-model="price" :decimal="decimal"/>
      </div>
      <div class="form-group">
        <!--Start-->
        <!--DropDown-->
        <div class="inner">
                    <div class="filter-select-option">
                        <label class="filter-leble">Currency</label>
                        <select v-model="selected" style="border-color:white; color: white; height: 50px; font-size: 20px;">
                            <option disabled value="">Please Select</option>
                            <option v-for="curr in assetName" :value="curr.assetName">{{curr.assetName}}</option>
                        </select>
                    </div>
                </div>
        <!--End-->
      </div>
      <button @click="createListing()" class="btn btn-primary">Submit</button>
  </div>
</template>
<script setup>
import { ref, getCurrentInstance, watch } from 'vue';
import NumberInput from '../input/NumberInput.vue';
import { store } from '../../store/store';
import {backend} from '../../store/storeUrl'
import axios from 'axios';
import {MosaicHttp, MosaicId} from 'tsjs-xpx-chain-sdk'
import { useToast } from 'primevue/usetoast';
import { useRouter } from 'vue-router';

const props = defineProps({
    nftId: String
})
const router = useRouter()
const price = ref('')
const decimal = ref(store.xpx.divisibility)
const data = ref({})
const toast = useToast()
const token= ref('')
const currency = ref([])
const selected = ref('')
const assetName = ref([])
const internalInstance = getCurrentInstance()
const emitter = internalInstance.appContext.config.globalProperties.emitter
token.value = sessionStorage.getItem('accessToken')
const publicKey = ref('')
publicKey.value = sessionStorage.getItem('userPublicKey')
const config = {
    headers: {
        authorization: `Bearer ${token.value}`
    }
}

// const getProduct = (nftId) => {
//           return ownNFT.value.find(item => item.id == nftId);
//     }

// getProduct(props.nftId)

const createListing = async() => { 
    if(decimal.value === 0){
        decimal.value = null
    }
    let [upper,lower] = price.value.split('.')
    if(!lower || parseFloat(lower) == 0){
        lower = null
    }
    data.value = {
        nftId: props.nftId,
        minPriceLower: lower?parseFloat(lower):null,
        minPriceUpper: upper,
        currencyId: store.xpx.assetId
    }
    await axios.get(backend.url+`/nft/id/${props.nftId}`,config).then((res)=>{
        if(!res.data.blockchainStatus){
            toast.add({severity:'error', summary: "Error", detail: "Nft is not verified", group: 'br', life: 3000});
            return
        }
        else{
            axios.post(backend.url+'/directListings/create',data.value,config).then((res)=>{
                toast.add({severity:'success', summary: "NFT is Listed", detail: "", group: 'br', life: 3000});
                document.querySelector('#uploadModal5').classList.remove('show');
                document.querySelector('body').classList.remove('modal-open');
                const mdbackdrop = document.querySelector('.modal-backdrop');
                if (mdbackdrop){
                    mdbackdrop.classList.remove('modal-backdrop', 'show');
                }
                router.push("/profile/" + publicKey.value)
            }).catch(e=>{
                toast.add({severity:'error', summary: e.response.statusText, detail:e.response.data.message, group: 'br', life: 3000});
            })
        }
    })

    
}

const getCurrency = () => {
    axios.get(backend.url+'/currency').then((res)=>{
        currency.value = res.data
        currency.value.map(async(index)=>{
            let mosaicHttp = new MosaicHttp(store.testApiEndPoint)
            let res = await mosaicHttp.getMosaicsNames([new MosaicId(index.assetId)]).toPromise()
            res.map((index)=>{
                let count = 0
                assetName.value.push({assetName:index.names[count].name})
                count +=1
            })
        })  
    })
}



emitter.on('list clicked',()=>{
    assetName.value = []
    getCurrency()
})



</script>
<style scoped>
    .container{
        padding: 20px;
        background: #000;
    }
</style>