<template>
    <layout>
        <breadcrumb title="Make your payment easier" current="Make your payment easier"/>

        <!-- Start connect area -->
        <div class="rn-connect-area rn-section-gapTop">
            <div class="container">
                <div class="row g mb--50 mb_md--30 mb_sm--30 align-items-center" v-if="address==''">
                    <div class="col-lg-6">
                        <h3 class="connect-title">Connect your wallet</h3>
                        <p class="connect-td">
                            Connect with one of available wallet providers or create a new wallet.
                            <router-link to="#">What is a wallet?</router-link>
                        </p>
                    </div>
                    <div class="col-lg-6">
                        <p class="wallet-bootm-disc">
                            We do not own your private keys and cannot access your funds without your confirmation.
                        </p>
                    </div>
                </div>
                <!--Image start-->
                <div class="row g-5">
                    <div class="col-lg-6">
                        <div class="connect-thumbnail">
                            <div class="left-image">
                                <div v-if="address===''">
                                <img :src="require(`@/assets/images/connect/walletInfo.jpg`)" alt="Nft_Profile">
                                </div>
                                <div v-else>
                                    <div class="containerShowWallet">
                                        <div>
                                            <h2>Your Address</h2>
                                        </div>
                                        <div class="walletDiv">
                                            <div class="walletCopy">{{address}}</div>
                                            <img @click="copyAddress" src="../assets/images/icons/copy-icon.svg" style="width:5%;">
                                        </div>
                                        <div class="containerAsset">
                                            <div class="assetDiv" v-for="(asset,index) in assets">
                                                <div class="assetName">
                                                <img :src= "require(`@/assets/images/logo/${asset.image}`)" :style="`${asset.style}`">
                                                    <div style="margin-left:15px;">{{asset.name}}</div> 
                                                </div>
                                                <div class="assetValue">
                                                    <div v-if="index===0">{{xpxDisplay}}</div>
                                                    <div v-else>{{metxDisplay}}</div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--Image End-->
                    <div class="col-lg-6">
                        <div class="row g-5">
                            <!-- Start single wallet -->
                            <div class="col-xxl-4 col-lg-6 col-md-4 col-12 col-sm-6 col-12">
                                <div class="wallet-wrapper" v-if="address===''" @click="generate()">
                                    <div class="inner">
                                        <div class="icon">
                                            <i class="feather-cast"/>
                                        </div>
                                        <div class="content">
                                            <h4 class="title"><a href="#" data-bs-toggle="modal" data-bs-target="#uploadModal2">Mobile Wallet</a></h4>
                                                
                                            <p class="description">I throw myself down among the tall.</p>
                                        </div>
                                    </div>
                                    <!-- <router-link class="over-link" to="/QR"></router-link> -->
                                </div>
                                <div class="wallet-wrapper" v-else>
                                    <div class="inner">
                                        <div class="icon">
                                            <i class="feather-cast"/>
                                        </div>
                                        <div class="content">
                                            <h4 class="title"><a>Wallet Connected</a></h4>
                                        </div>
                                    </div>
                                    <!-- <router-link class="over-link" to="/QR"></router-link> -->
                                </div>
                            </div>
                        
                            <!-- model -->
                            <div class="rn-popup-modal upload-modal-wrapper modal fade" id="uploadModal2" tabindex="-1" aria-hidden="true">
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                        <i class="feather-x"/>
                                    </button>
                                    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                                        <div class="modal-content share-wrapper">
                                            <div class="modal-body">
                                                <qr-connect/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                 
                            <!-- End single wallet -->

                            <!-- Start single wallet -->
                            <div class="col-xxl-4 col-lg-6 col-md-4 col-12 col-sm-6 col-12">
                                <div class="wallet-wrapper">
                                    <div class="inner">
                                        <div class="icon">
                                            <i class="feather-box color-purple"/>
                                        </div>
                                        <div class="content">
                                            <h4 class="title"><a href="#">GrasCash</a></h4>
                                            <p class="description">This is a great deals For cash transfer</p>
                                        </div>
                                    </div>
                                    <router-link class="over-link" to="#"></router-link>
                                </div>
                            </div>
                            <!-- End single wallet -->

                            <!-- Start single wallet -->
                            <div class="col-xxl-4 col-lg-6 col-md-4 col-12 col-sm-6 col-12">
                                <div class="wallet-wrapper">
                                    <div class="inner">
                                        <div class="icon">
                                            <i class="feather-award color-pink"/>
                                        </div>
                                        <div class="content">
                                            <h4 class="title">
                                                <router-link to="#">Import</router-link>
                                            </h4>
                                            <p class="description">Great opportunity to reach them.</p>
                                        </div>
                                    </div>
                                    <router-link class="over-link" to="#"></router-link>
                                </div>
                            </div>
                            <!-- End single wallet -->

                            <!-- Start single wallet -->
                            <div class="col-xxl-4 col-lg-6 col-md-4 col-12 col-sm-6 col-12">
                                <div class="wallet-wrapper">
                                    <div class="inner">
                                        <div class="icon">
                                            <i class="feather-briefcase color-yellow"/>
                                        </div>
                                        <div class="content">
                                            <h4 class="title">
                                                <router-link to="#">TiOne</router-link>
                                            </h4>
                                            <p class="description">Built your bigger offers then me</p>
                                        </div>
                                    </div>
                                    <router-link class="over-link" to="#"></router-link>
                                </div>
                            </div>
                            <!-- End single wallet -->

                            <!-- Start single wallet -->
                            <div class="col-xxl-4 col-lg-6 col-md-4 col-12 col-sm-6 col-12">
                                <div class="wallet-wrapper">
                                    <div class="inner">
                                        <div class="icon">
                                            <i class="color-green feather-command"/>
                                        </div>
                                        <div class="content">
                                            <h4 class="title"><a href="#">Bkashes</a></h4>
                                            <p class="description">Cash Transfer for easiest way you wast</p>
                                        </div>
                                    </div>
                                    <router-link class="over-link" to="#"></router-link>
                                </div>
                            </div>
                            <!-- End single wallet -->

                            <!-- Start single wallet -->
                            <div class="col-xxl-4 col-lg-6 col-md-4 col-12 col-sm-6 col-12">
                                <div class="wallet-wrapper">
                                    <div class="inner">
                                        <div class="icon">
                                            <i class="color-blue feather-cpu"/>
                                        </div>
                                        <div class="content">
                                            <h4 class="title">
                                                <router-link to="#">Pyynle</router-link>
                                            </h4>
                                            <p class="description">More then myself down among the Cash.</p>
                                        </div>
                                    </div>
                                    <router-link class="over-link" to="#"></router-link>
                                </div>
                            </div>
                            <!-- End single wallet -->

                            <!-- Start single wallet -->
                            <div class="col-12">
                                <div class="wallet-wrapper">
                                    <div class="inner">
                                        <div class="icon">
                                            <i class="color-red feather-gitlab"/>
                                        </div>
                                        <div class="content">
                                            <h4 class="title"><a href="">YesCash</a></h4>
                                            <p class="description">Biggest Bank transfer for best opportunity</p>
                                        </div>
                                    </div>
                                    <router-link class="over-link" to="#"></router-link>
                                </div>
                            </div>
                            <!-- End single wallet -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End connect area -->
    </layout>
</template>

<script setup>
import Layout from '@/components/layouts/Layout'
import Breadcrumb from '@/components/breadcrumb/Breadcrumb'
import QrConnect from '../components/wallet/QrConnect.vue'
import { NetworkType, PublicAccount, AccountHttp, Address } from "tsjs-xpx-chain-sdk"
import {getCurrentInstance,ref, onMounted, computed, inject} from 'vue'
import {isConnected} from '../auth/auth'
import {store} from "../store/store"


let network = store.networkType
const internalInstance = getCurrentInstance()
const emitter = internalInstance.appContext.config.globalProperties.emitter

const toCurrencyFormat=(value, divisibility)=>{
    const exactValue = value;
    return new Intl.NumberFormat('en', { maximumFractionDigits: divisibility }).format(exactValue);
}

const xpxBalance = ref(0)
const xpxDisplay = computed(()=>{
    return toCurrencyFormat(xpxBalance.value,store.xpx.divisibility)
})
const metxBalance = ref(0)
const metxDisplay = computed(()=>{
    return toCurrencyFormat(metxBalance.value,store.metx.divisibility)
})
const address = ref('')
const assets = ref([
    {
        image: 'proximax-logo.png',
        name: "XPX",
        style:"width: 9%",
    },
    {
        image: 'metx.png',
        name: "METX",
        style:"width: 30%",
    }
])


const fetchSessionStorage = () =>{
  const publicKey = sessionStorage.getItem('userPublicKey')
  if(publicKey!=null){
    address.value = PublicAccount.createFromPublicKey(publicKey, network).address.pretty()
    getBalanceAsset()
  }else{
    address.value = ""
  }
}

const getBalanceAsset = async() =>{
    let API_Endpoint = store.testApiEndPoint;
    let accountHttp = new AccountHttp(API_Endpoint);
    const publicKey = sessionStorage.getItem('userPublicKey')
    if(publicKey === null){
        return
    }
    let address = Address.createFromPublicKey(publicKey, network);

    let accountInfo = await accountHttp.getAccountInfo(address).toPromise();
    let xpxAsset = accountInfo.mosaics.find(asset=>asset.id.toHex().toLowerCase() === store.xpx.assetId)
    let metxAsset = accountInfo.mosaics.find(asset=>asset.id.toHex().toLowerCase() === store.metx.assetId)
    if(xpxAsset){
        xpxBalance.value = xpxAsset.amount.compact() / Math.pow(10,6)
    }
    if(metxAsset){
        metxBalance.value = metxAsset.amount.compact() / Math.pow(10,6)
    }
}





onMounted(()=>{
  fetchSessionStorage()
})

emitter.on("Mobile Wallet Connected",()=>{
    fetchSessionStorage()
    isConnected.value = true

document.querySelector('#uploadModal2').classList.remove('show');
  document.querySelector('body').classList.remove('modal-open');
  const mdbackdrop = document.querySelector('.modal-backdrop');
  if (mdbackdrop){
    mdbackdrop.classList.remove('modal-backdrop', 'show');
  }
    
})

emitter.on("Mobile Wallet Disconnected",()=>{
    address.value = ''
})

const generate = () => {
    emitter.emit("Button Clicked")
}

const copyAddress = () => {
    navigator.clipboard.writeText(address.value)
    alert("copied")
}


</script>

<style scoped>
.containerShowWallet{
   background-color: #24243557;
}
.walletDiv{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 98%;
    background-color: transparent;
    color: grey;
    font-size: large;
    padding: 2px;
    border-radius: 4px;
    height: 54px;
    outline-style: solid;
    outline-color: white;
    margin-left: 3px;
}
.assetDiv{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top:30px;
    margin-bottom: 10px;
    font-size: 30px;
}

.assetName{
    display:flex;
    align-items: center;
}

.assetValue{
    font-weight: bold;
}

</style>