<template>
    <layout>
        <div class="d-flex" style="justify-content:center; margin-top: 15px;">
            <div>
        <div class="connect-bg-color text-center py-2 rounded text-lg font-weight-normal fs-2" style="padding:2rem;">Mint Asset</div>
        <div class="d-flex connect-bg-color dark:border-blue-600 dark:bg-[#171717]" style="padding-left: 1.5rem; padding-right: 1.5rem;padding-top: 0.5rem;padding-bottom: 6rem;border-bottom-width: 2px;	--tw-border-opacity: 1;border-color: rgba(37, 99, 235, var(--tw-border-opacity));justify-content: flex-start; align-items: center;">
          <div>
            <div class="my-6" style="margin-top: 1.5rem;margin-bottom: 1.5rem; width: 100%;" v-html="qr"/>
            <div class="text-gray-500 text-middle fs-3">Scan the QR above using NFT Marketplace Account to mint your asset.</div>
            <div class="text-gray-500 text-middle fs-3"></div>
          </div>
        </div>
        <div class="d-flex justify-content-center dark:bg-[#171717] connect-bg-color">
          <button class="my-3 py-1.5 px-6 text-center text-white rounded-xl fs-2">Learn how to connect</button>
        </div>
      </div>
        </div>
    </layout>
</template>

<script setup>
import Layout from '@/components/layouts/Layout'
import { useRouter } from 'vue-router'
import { onMounted, ref } from 'vue'
import { useToast } from "primevue/usetoast";
import axios from 'axios'
import { store } from "../store/store.js";    
import {  AggregateBondedTransactionBuilder, ChainHttp, Convert, Deadline, Mosaic, MosaicDefinitionTransactionBuilder, MosaicId, MosaicMetadataTransactionBuilder, MosaicNonce, MosaicProperties, MosaicSupplyChangeTransactionBuilder, MosaicSupplyType, NamespaceId, PublicAccount, TransactionHttp, TransferTransactionBuilder, UInt64 } from 'tsjs-xpx-chain-sdk';
import Peer from 'peerjs';
import QRCode from 'qrcode'

    const props = defineProps({
        id: String
    })
    const nft = ref(null)
    const router = useRouter()
    const properties = ref(null)
    const publicKey = ref('')
    const toast = useToast()
    const qr = ref('')
    const nftName = ref('') 
    const description = ref('')
    const image = ref('')
    const escrowPublicKey = ref('')
    const nonce = ref('')

    const fetchSessionStorage = () => {
        publicKey.value = sessionStorage.getItem('userPublicKey')
    }
   
    const checkOwnNft = async() => {
        await axios.get(`https://nftm-backend.proximaxtest.com/nft/id/${props.id}`).then((res)=>{
            let data = res.data
            if(!data || !data.owner.escrowPublicKey || data.owner.walletPublicKey!=publicKey.value ||
             data.blockchainStatus){
                router.push("/")
                return
            }
            nft.value = data
            properties.value = data.properties
            nftName.value = data.name 
            description.value = data.description
            nonce.value = data.nonce
            image.value =  store.imageUrl + '/' + data.file.s3Uri
            escrowPublicKey.value = data.owner.escrowPublicKey

        }).catch(e=>{
            toast.add({severity:'error', summary: e.response.statusText, detail:e.response.data.message, group: 'br', life: 3000});
        })
        
        
    }

    onMounted(async()=>{
        fetchSessionStorage()
        await checkOwnNft()
        createItem()
    })
    
    //define abtTxn that includes transferTxn and convertMultisigTxn and display qr for mobile to sign
    const createItem = async() =>{
        const newValue = {
            name: nftName.value, 
            description: description.value,
            image: image.value,  
            attributes: properties.value
        }

        
        
        const publicAccount = PublicAccount.createFromPublicKey(escrowPublicKey.value,store.networkType)
        let chainApi = new ChainHttp(store.testApiEndPoint)
        let blockchainHeight = await chainApi.getBlockchainHeight().toPromise()
        let res = await axios.get(store.testApiEndPoint + '/config/' + blockchainHeight.compact())
        let data = res.data.networkConfig
        const regex = /[^=\n{1}]+=\s*(.*)/g;
        const configs = data.networkConfig.match(regex);

        
        const networkConfig = configs.reduce((result, data)=>{
        const [config, value] = data.split("=");
        result[config.trim()] = value.trim();
        return result;
        }, {});
        let assetCreationFee = parseInt(networkConfig['mosaicRentalFee'].split("'").join(""))
        
        const transferTxnBuilder = new TransferTransactionBuilder()
        const transferTxn = transferTxnBuilder
        .deadline(Deadline.create())
        .recipient(publicAccount.address) 
        .networkType(store.networkType)
        .mosaics([new Mosaic(new NamespaceId('prx.xpx'),UInt64.fromUint(assetCreationFee))])
        .build();

        const assetDefinitionBuilder = new MosaicDefinitionTransactionBuilder() 
        const mosaicNonce = MosaicNonce.createFromNumber(parseFloat(nonce.value)); 
        const assetDefinitionTx = assetDefinitionBuilder
        .deadline(Deadline.create())
        .mosaicNonce(mosaicNonce)
        .mosaicId(MosaicId.createFromNonce(mosaicNonce, publicAccount)) 
        .mosaicProperties( 
            MosaicProperties.create({
                supplyMutable: false, 
                transferable: true,
                divisibility: 0,
                duration: undefined
            })
        )
        .networkType(store.networkType)
        .build();
        
        const assetSupplyChangeBuilder = new MosaicSupplyChangeTransactionBuilder() 
        const assetSupplyChangeTx =  assetSupplyChangeBuilder
        .deadline(Deadline.create())
        .mosaicId(assetDefinitionTx.mosaicId)
        .direction(MosaicSupplyType.Increase) 
        .delta(UInt64.fromUint(1))
        .networkType(store.networkType)
        .build()

        const mosaicMetadataBuilder = new MosaicMetadataTransactionBuilder() 
        const mosaicMetadataTx = mosaicMetadataBuilder
        .deadline(Deadline.create())
        .targetPublicKey(publicAccount)
        .targetMosaicId(assetDefinitionTx.mosaicId) 
        .scopedMetadataKey(UInt64.fromHex(Convert.utf8ToHex('nft.json')))
        .value(JSON.stringify(newValue))
        .oldValue('')
        .calculateDifferences()
        .networkType(store.networkType)
        .build()

        let innerTx = []
        = [transferTxn.toAggregate(PublicAccount.createFromPublicKey(publicKey.value,store.networkType)),assetDefinitionTx.toAggregate(publicAccount),assetSupplyChangeTx.toAggregate(publicAccount),mosaicMetadataTx.toAggregate(publicAccount)]
        
        let aggregateTxBuilder =  new AggregateBondedTransactionBuilder() 
        const aggregateTx = aggregateTxBuilder
        .deadline(Deadline.create())
        .innerTransactions(innerTx)
        .networkType(store.networkType) 
        .build()
        const peer = new Peer(); 
        peer.on("open", async () => {
            const data = {
                payload: peer.id,
                type:'reqPeerID',
                generationHash: store.generationHash, //testnet2
                recvId: peer.id
            } 
            qr.value = await QRCode.toString(JSON.stringify(data))
        })
        peer.on("connection", (conn) => {
            conn.on("data", async (data) => {
                let payload = {
                    type: 'txn',
                    payload: aggregateTx.serialize()
                }
                if(data == 'requestTxnSigning'){
                    conn.send(payload) 
                }else if(data === 'txnHash'){
                    conn.send("success"); 
                }else{
                    const transactionHttp = new TransactionHttp(store.testApiEndPoint);
                    await sleep(2000);
                    let res = await transactionHttp.getTransactionStatus(data).toPromise();
                    if (!res) {
                        await sleep(2000);
                        let res = await transactionHttp
                        .getTransactionStatus(txnHash)
                        .toPromise();
                        if (!res) {
                        toast.add({
                            severity: "error",
                            summary: "Failed to save transaction hash",
                            detail: "Transaction hash is not known by network",
                            group: "br",
                            life: 3000,
                        });
                        }
                        if (res.status == "Success" && res.group == "partial") {
                        postTxnHash(data);
                        }
                        return;
                    }
                    if (res.status == "Success" && res.group == "partial") {
                        postTxnHash(data);
                    }
                }
            });
        });

    }

    const sleep = (milliseconds) => {
        return new Promise((resolve) => {
            setTimeout(resolve, milliseconds);
        });
    };

    const postTxnHash = (txnHash) => {
        const config = {
            headers: {
            authorization: "Bearer " + sessionStorage.getItem('accessToken'),
            },
        };
        const data = {
            txnHash: txnHash,
        };
        const url = store.backendUrl + "/txn/asset";
        axios.post(url, data, config).then((res) => {
            if (res.status === 201) {
                toast.add({
                    severity: "success",
                    summary: "Success",
                    detail: "Please wait for admin to approve",
                    group: "br",
                    life: 3000,
                });
            }
        })
        .catch((e) => {
            toast.add({
                severity: "error",
                summary: e.response.statusText,
                detail: e.response.data.message,
                group: "br",
                life: 3000,
            });
        });
    };


</script>