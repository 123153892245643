<template>
  <layout>
    <div class="d-flex" style="justify-content:center; margin-top: 15px;">
      <transition
        enter-active-class="animate__animated animate__fadeInDown"
        leave-active-class="animate__animated animate__fadeOutUp"
        v-if="!toggle"
      >
        <div class="modal-popup-box">
          <div
            class="text-center py-2 rounded text-lg font-weight-normal fs-2"
            style="padding: 2rem"
          >
            Import NFT Marketplace Account
          </div>
          <div
            class="d-flex"
            style="
              padding-left: 1.5rem;
              padding-right: 1.5rem;
              padding-top: 0.5rem;
              padding-bottom: 6rem;
              border-bottom-width: 2px;
              --tw-border-opacity: 1;
              border-color: rgba(37, 99, 235, var(--tw-border-opacity));
              justify-content: flex-start;
              align-items: center;
            "
          >
            <div>
              <div
                class="my-6"
                style="margin-top: 1.5rem; margin-bottom: 1.5rem"
                v-html="qr1"
              />
              <div class="text-gray-500 text-middle fs-3">
                Scan the QR code above with Sirius Mobile to import a new NFT Marketplace account.
              </div>
            </div>
          </div>
          <div
            class="d-flex justify-content-center dark:bg-[#171717] connect-bg-color"
          >
            <button
              class="my-3 py-1.5 px-6 text-center text-white rounded-xl fs-2"
              @click="modifyMultisigTransaction()"
            >
              Next
            </button>
          </div>
        </div>
      </transition>
      <transition
        enter-active-class="animate__animated animate__fadeInDown"
        leave-active-class="animate__animated animate__fadeOutUp"
        v-else
      >
        <div class="modal-popup-box">
          <div
            class="text-center py-2 rounded text-lg font-weight-normal fs-2"
            style="padding: 2rem"
          >
            Multisig Account
          </div>
          <div
            class="d-flex dark:border-blue-600 dark:bg-[#171717]"
            style="
              padding-left: 1.5rem;
              padding-right: 1.5rem;
              padding-top: 0.5rem;
              padding-bottom: 6rem;
              border-bottom-width: 2px;
              --tw-border-opacity: 1;
              border-color: rgba(37, 99, 235, var(--tw-border-opacity));
              justify-content: flex-start;
              align-items: center;
            "
          >
            <div>
              <div
                class="my-6"
                style="margin-top: 1.5rem; margin-bottom: 1.5rem"
                v-html="qr2"
              />
              <div class="text-gray-500 text-middle fs-3">
                Scan the QR code above with your Main wallet to activate your NFT Marketplace account.
              </div>
            </div>
          </div>
          <div
            class="d-flex justify-content-center dark:bg-[#171717] connect-bg-color"
          >
            <button
              class="my-3 py-1.5 px-6 text-center text-white rounded-xl fs-2"
              @click="toggle = false"
            >
              Back
            </button>
          </div>
        </div>
      </transition>
    </div>
  </layout>
</template>
<script setup>
import Layout from "../components/layouts/Layout.vue";
import {
  Account,
  AccountHttp,
  AggregateBondedTransactionBuilder,
  AggregateCompleteTransactionBuilder,
  Deadline,
  ModifyMultisigAccountTransactionBuilder,
  Mosaic,
  MultisigCosignatoryModification,
  MultisigCosignatoryModificationType,
  NamespaceId,
  PublicAccount,
  TransactionHttp,
  TransferTransactionBuilder,
  UInt64,
} from "tsjs-xpx-chain-sdk";
import { getCurrentInstance, ref, onMounted } from "vue";
import QRCode from "qrcode";
import { storeAccount } from "../store/storeAccount";
import { store } from "../store/store.js";
import Peer from "peerjs";
import axios from "axios";
import { useToast } from "primevue/usetoast";
import router from "../router";

const internalInstance = getCurrentInstance();
const emitter = internalInstance.appContext.config.globalProperties.emitter;
const toast = useToast();
const toggle = ref(false);
const userPrivateKey = ref("");
const userPublicKey = ref("");
userPublicKey.value = sessionStorage.getItem("userPublicKey");
const accessToken = ref("");
accessToken.value = sessionStorage.getItem("accessToken");
const qr1 = ref("");
const qr2 = ref("");
//generate an account and display qr code
const displayNewAccountQr = async () => {
  let privateKey = Account.generateNewAccount(store.networkType).privateKey;
  userPrivateKey.value = privateKey;
  qr1.value = await QRCode.toString(privateKey);
};

//calculate max fee for both transfer and multisig txn
const calculateMaxFee = () => {
  const test_publicKey = "0".repeat(64);
  let multisigModification = [];
  for (let i = 0; i < 3; i++) {
    multisigModification.push(
      new MultisigCosignatoryModification(
        MultisigCosignatoryModificationType.Add,
        PublicAccount.createFromPublicKey(test_publicKey, store.networkType)
      )
    );
  }
  const multisigTxnBuilder = new ModifyMultisigAccountTransactionBuilder();
  const convertIntoMultisigTxn = multisigTxnBuilder
    .deadline(Deadline.create())
    .networkType(store.networkType)
    .minApprovalDelta(2)
    .minRemovalDelta(3)
    .modifications(multisigModification)
    .build();

  const transferTxnBuilder = new TransferTransactionBuilder();
  const transferTxn = transferTxnBuilder
    .deadline(Deadline.create())
    .recipient(
      PublicAccount.createFromPublicKey(test_publicKey, store.networkType)
        .address
    )
    .networkType(store.networkType)
    .mosaics([new Mosaic(new NamespaceId("prx.xpx"), UInt64.fromUint(200))])
    .build();
  let innerTxn = [
    transferTxn.toAggregate(
      PublicAccount.createFromPublicKey(test_publicKey, store.networkType)
    ),
    convertIntoMultisigTxn.toAggregate(
      PublicAccount.createFromPublicKey(test_publicKey, store.networkType)
    ),
  ];
  let aggregateBondedTx = new AggregateCompleteTransactionBuilder()
    .deadline(Deadline.create())
    .networkType(store.networkType)
    .innerTransactions(innerTxn)
    .build();
  return (
    aggregateBondedTx.maxFee.compact() / Math.pow(10, store.xpx.divisibility)
  );
};

//define abtTxn that includes transferTxn and convertMultisigTxn and display qr for mobile to sign
const modifyMultisigTransaction = async () => {
  //in case userPublicKey is null
  if (!userPublicKey.value) {
    return;
  }

  toggle.value = true;
  const accountToConvert = Account.createFromPrivateKey(
    userPrivateKey.value,
    store.networkType
  );
  const operatorPublicAccount = PublicAccount.createFromPublicKey(
    storeAccount.operator,
    store.networkType
  );
  const adminPublicAccount = PublicAccount.createFromPublicKey(
    storeAccount.admin,
    store.networkType
  );
  const userPublicAccount = PublicAccount.createFromPublicKey(
    userPublicKey.value,
    store.networkType
  );

  const transferTxnBuilder = new TransferTransactionBuilder();
  const transferTxn = transferTxnBuilder
    .deadline(Deadline.create())
    .recipient(accountToConvert.address)
    .networkType(store.networkType)
    .mosaics([])
    .build();

  let cosigners = [];
  cosigners.push(userPublicAccount, operatorPublicAccount, adminPublicAccount);
  let multisigModification = [];
  cosigners.forEach((publicAcc) => {
    multisigModification.push(
      new MultisigCosignatoryModification(
        MultisigCosignatoryModificationType.Add,
        publicAcc
      )
    );
  });
  const multisigTxnBuilder = new ModifyMultisigAccountTransactionBuilder();
  const convertIntoMultisigTxn = multisigTxnBuilder
    .deadline(Deadline.create())
    .networkType(store.networkType)
    .minApprovalDelta(2)
    .minRemovalDelta(3)
    .modifications(multisigModification)
    .build();

  let innerTxn = [
    transferTxn.toAggregate(userPublicAccount),
    convertIntoMultisigTxn.toAggregate(accountToConvert.publicAccount),
  ];

  const abtTxnBuilder = new AggregateBondedTransactionBuilder();
  const abtTxn = abtTxnBuilder
    .deadline(Deadline.create())
    .networkType(store.networkType)
    .innerTransactions(innerTxn)
    .build();

  const peer = new Peer(); 
  peer.on("open", async () => {
      const data = {
          payload: peer.id,
          type:'reqPeerID',
          generationHash: store.generationHash, //testnet2
          recvId: peer.id
      } 
      qr2.value = await QRCode.toString(JSON.stringify(data))
  })
  peer.on("connection", (conn) => {
      conn.on("data", async (data) => {
          let payload = {
              type: 'txn',
              payload: abtTxn.serialize()
          }
          if(data == 'requestTxnSigning'){
              conn.send(payload) 
          }else if(data === 'txnHash'){
              conn.send("success"); 
          }else{
              const transactionHttp = new TransactionHttp(store.testApiEndPoint);
              await sleep(2000);
              let res = await transactionHttp.getTransactionStatus(data).toPromise();
              if (!res) {
                  await sleep(2000);
                  let res = await transactionHttp
                  .getTransactionStatus(txnHash)
                  .toPromise();
                  if (!res) {
                  toast.add({
                      severity: "error",
                      summary: "Failed to save transaction hash",
                      detail: "Transaction hash is not known by network",
                      group: "br",
                      life: 3000,
                  });
                  }
                  if (res.status == "Success" && res.group == "partial") {
                  postTxnHash(data);
                  }
                  return;
              }
              if (res.status == "Success" && res.group == "partial") {
                  postTxnHash(data);
              }
          }
      });
  });
};

const sleep = (milliseconds) => {
  return new Promise((resolve) => {
    setTimeout(resolve, milliseconds);
  });
};

const postTxnHash = (txnHash) => {
  const config = {
    headers: {
      authorization: "Bearer " + accessToken.value,
    },
  };
  const data = {
    txnHash: txnHash,
  };
  const url = store.backendUrl + "/txn/multisig";
  axios
    .post(url, data, config)
    .then((res) => {
      if (res.status === 201) {
        toast.add({
          severity: "success",
          summary: "Success",
          detail: "Please wait for admin to approve",
          group: "br",
          life: 3000,
        });
        router.push("/profile/" + userPublicKey.value)
      }
    })
    .catch((e) => {
      toast.add({
        severity: "error",
        summary: e.response.statusText,
        detail: e.response.data.message,
        group: "br",
        life: 3000,
      });
    });
};

const check = () => {
    axios.get(`https://nftm-backend.proximaxtest.com/users/owner/${userPublicKey.value}`,{
        headers: {
            authorization: "Bearer " + accessToken.value
        }
}).then((res)=>{
    if(res.data.escrowPublicKey){
        router.push('/profile/' + userPublicKey.value)
    }
})
}

onMounted(() => {
    check()
    displayNewAccountQr()
})

</script>
