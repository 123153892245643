<template>
    <layout>
        <breadcrumb title="Edit Profile" current="Edit Profile"/>

        <div class="edit-profile-area rn-section-gapTop">
            <div class="container">
                <div class="row plr--70 padding-control-edit-wrapper pl_md--0 pr_md--0 pl_sm--0 pr_sm--0">
                    <div class="col-12 d-flex justify-content-between mb--30 align-items-center">
                        <h4 class="title-left">Edit Your Profile</h4>
                    </div>
                </div>
                <div class="row plr--70 padding-control-edit-wrapper pl_md--0 pr_md--0 pl_sm--0 pr_sm--0">
                    <!-- <div class="col-lg-3 col-md-3 col-sm-12">
                        <nav class="left-nav rbt-sticky-top-adjust-five">
                            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                <button class="nav-link active"
                                        id="nav-home-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#nav-home"
                                        type="button"
                                        role="tab"
                                        aria-controls="nav-home"
                                        aria-selected="true">
                                    <i class="feather-edit"/> Edit Profile Image
                                </button>
                               
                            </div>
                        </nav>
                    </div> -->
                    <div class="col-lg-9 col-md-9 col-sm-12 mt_sm--30">
                        <div class="tab-content tab-content-edit-wrapepr" id="nav-tabContent">
                            <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                <div class="nuron-information">
                                    <div class="profile-change row ">
                                        <div class="profile-left col-lg-6">
                                            <div class="profile-image mb--20 mt--20">
                                                <h6 class="title">Change Your Profile Picture</h6>
                                                <div class="upload-area">
                                                    <div class="brows-file-wrapper">
                                    
                                                        <input
                                                        id="profilePic"
                                                        type="file"
                                                        class="inputfile"
                                                        name="profilePic"
                                                        @change="profImageChange"
                                                        />
                                                        <img
                                                        v-if="profSelectedImage"
                                                        id="createfileImage"
                                                        :src="profSelectedImage"
                                                        alt=""
                                                        data-black-overlay="6"
                                                        />
                                                        <label for="profilePic" title="No File Choosen">
                                                        <!-- <i v-if="profSelectedImage" :src="profSelectedImage" /> -->
                                                        <i v-if="!profSelectedImage" class="feather-upload" />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h6 class="title mt--20">Change Your Banner Picture</h6>
                                        <div class="upload-area">
                                            <div class="brows-file-wrapper">
                                                
                                                <input
                                                id="bannerPic"
                                                type="file"
                                                name="bannerPic"
                                                class="inputfile"
                                                @change="coverImageChange"
                                                />
                                                <img
                                                v-if="coverSelectedImage"
                                                id="createfileImage"
                                                :src="coverSelectedImage"
                                                alt=""
                                                data-black-overlay="6"
                                                />
                                                <label for="bannerPic" title="No File Choosen">
                                                <i v-if="!coverSelectedImage" class="feather-upload" />
                                                    
                                                    
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="button-area save-btn-edit">
                                        <a class="btn btn-primary " style="" @click="uploadProfilePic()">Save</a>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="tab-pane fade" id="nav-homes" role="tabpanel" aria-labelledby="nav-home-tab">
                                <div class="nuron-information">
                                    <div class="profile-form-wrapper">
                                        <div class="input-two-wrapper mb--15">
                                            <div class="first-name half-wid">
                                                <label for="contact-name" class="form-label">First Name</label>
                                                <input id="contact-name" type="text" value="Mr.">
                                            </div>
                                            <div class="last-name half-wid">
                                                <label for="contact-name-last" class="form-label">Last Name</label>
                                                <input id="contact-name-last" type="text" value="Sunayra">
                                            </div>
                                        </div>
                                        <div class="email-area">
                                            <label for="Email" class="form-label">Edit Your Email</label>
                                            <input id="Email" type="email" value="example@gmail.com">
                                        </div>
                                    </div>
                                    <div class="edit-bio-area mt--30">
                                        <label for="description" class="form-label">Edit Your Bio</label>
                                        <textarea id="description">Hello, I am Alamin, A Front-end Developer...</textarea>
                                    </div>
                                    <div class="input-two-wrapepr-prifile">
                                        <div class="role-area mt--15">
                                            <label for="Role" class="form-label mb--10">Your Role</label>
                                            <input id="Role" type="text" value="Front-end Developer">
                                        </div>
                                        <select class="profile-edit-select">
                                            <option selected>Select Your Gender</option>
                                            <option value="1">Male</option>
                                            <option value="2">Female</option>
                                            <option value="3">Third Gender</option>
                                        </select>
                                    </div>

                                    <div class="input-two-wrapper mt--15">
                                        <div class="half-wid currency">
                                            <select class="profile-edit-select">
                                                <option selected>Currency</option>
                                                <option value="1">($)USD</option>
                                                <option value="2">wETH</option>
                                                <option value="3">BIT Coin</option>
                                            </select>
                                        </div>
                                        <div class="half-wid phone-number">
                                            <label for="PhoneNumber" class="form-label">Phone Number</label>
                                            <input id="PhoneNumber" type="text" value="+880100000000">
                                        </div>
                                    </div>
                                    <div class="input-two-wrapper mt--15">
                                        <div class="half-wid currency">
                                            <select class="profile-edit-select">
                                                <option selected>Location</option>
                                                <option value="1">United State</option>
                                                <option value="2">Katar</option>
                                                <option value="3">Canada</option>
                                            </select>
                                        </div>
                                        <div class="half-wid phone-number">
                                            <label for="address" class="form-label">Address</label>
                                            <input id="address" type="text" value="USA Cidni">
                                        </div>
                                    </div>
                                    <div class="button-area save-btn-edit">
                                        <a href="#" class="btn btn-primary-alta mr--15" @click="alert('Cancel Edit Profile?')">Cancel</a>
                                        <a href="#" class="btn btn-primary" @click="alert('Successfully Saved Your Profile?')">Save</a>
                                    </div>
                                </div>
                            </div> -->

                            <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                <div class="nuron-information">
                                    <div class="condition">
                                        <h5 class="title">Create Your Password</h5>
                                        <p class="condition">
                                            Passwords are a critical part of information and network security. Passwords
                                            serve to protect user accounts but a poorly chosen password, if compromised,
                                            could put the entire network at risk.
                                        </p>
                                        <hr />
                                        <div class="email-area">
                                            <label for="Email2" class="form-label">Enter Email</label>
                                            <input id="Email2" type="email" value="">
                                        </div>
                                    </div>
                                    <div class="input-two-wrapper mt--15">
                                        <div class="old-password half-wid">
                                            <label for="oldPass" class="form-label">Enter Old Password</label>
                                            <input id="oldPass" type="password">
                                        </div>
                                        <div class="new-password half-wid">
                                            <label for="NewPass" class="form-label">Create New Password</label>
                                            <input id="NewPass" type="password">
                                        </div>
                                    </div>
                                    <div class="email-area mt--15">
                                        <label for="rePass" class="form-label">Confirm Password</label>
                                        <input id="rePass" type="password">
                                    </div>
                                    <a href="#" class="btn btn-primary save-btn-edit" @click="alert('Successfully Changed Password?')">Save</a>
                                </div>
                            </div>

                            <div class="tab-pane fade " id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                                <div class="nuron-information">
                                    <h5 class="title">Make Sure Your Notification setting </h5>
                                    <p class="notice-disc">
                                        Notification Center is where you can find app notifications and Quick Settings—which
                                        give you quick access to commonly used settings and apps. You can change your
                                        notification settings at any time from the Settings app. Select Start , then select
                                        Settings
                                    </p>
                                    <hr/>

                                    <div class="notice-parent-wrapper d-flex">
                                        <div class="notice-child-wrapper">
                                            <!-- single notice wrapper -->
                                            <div class="single-notice-setting">
                                                <div class="input">
                                                    <input type="checkbox" id="themeSwitch" name="theme-switch" class="theme-switch__input" />
                                                    <label for="themeSwitch" class="theme-switch__label">
                                                        <span></span>
                                                    </label>
                                                </div>
                                                <div class="content-text">
                                                    <p>Order Confirmation Notice</p>
                                                </div>
                                            </div>
                                            <!-- single notice wrapper End -->

                                            <!-- single notice wrapper -->
                                            <div class="single-notice-setting mt--15">
                                                <div class="input">
                                                    <input type="checkbox" id="themeSwitchs" name="theme-switch" class="theme-switch__input" />
                                                    <label for="themeSwitchs" class="theme-switch__label">
                                                        <span></span>
                                                    </label>
                                                </div>
                                                <div class="content-text">
                                                    <p>New Items Notification</p>
                                                </div>
                                            </div>
                                            <!-- single notice wrapper End -->

                                            <!-- single notice wrapper -->
                                            <div class="single-notice-setting mt--15">
                                                <div class="input">
                                                    <input type="checkbox" id="OrderNotice" name="theme-switch" class="theme-switch__input" />
                                                    <label for="OrderNotice" class="theme-switch__label">
                                                        <span></span>
                                                    </label>
                                                </div>
                                                <div class="content-text">
                                                    <p>New Bid Notification</p>
                                                </div>
                                            </div>
                                            <!-- single notice wrapper End -->

                                            <!-- single notice wrapper -->
                                            <div class="single-notice-setting mt--15">
                                                <div class="input">
                                                    <input type="checkbox" id="newPAy" name="theme-switch" class="theme-switch__input" />
                                                    <label for="newPAy" class="theme-switch__label">
                                                        <span></span>
                                                    </label>
                                                </div>
                                                <div class="content-text">
                                                    <p>Payment Card Notification</p>
                                                </div>
                                            </div>
                                            <!-- single notice wrapper End -->

                                            <!-- single notice wrapper -->
                                            <div class="single-notice-setting mt--15">
                                                <div class="input">
                                                    <input type="checkbox" id="EndBid" name="theme-switch" class="theme-switch__input" />
                                                    <label for="EndBid" class="theme-switch__label">
                                                        <span></span>
                                                    </label>
                                                </div>
                                                <div class="content-text">
                                                    <p>Ending Bid Notification Before 5 min</p>
                                                </div>
                                            </div>
                                            <!-- single notice wrapper End -->

                                            <!-- single notice wrapper -->
                                            <div class="single-notice-setting mt--15">
                                                <div class="input">
                                                    <input type="checkbox" id="Approve" name="theme-switch" class="theme-switch__input" />
                                                    <label for="Approve" class="theme-switch__label">
                                                        <span></span>
                                                    </label>
                                                </div>
                                                <div class="content-text">
                                                    <p>Notification for approving product</p>
                                                </div>
                                            </div>
                                            <!-- single notice wrapper End -->
                                        </div>
                                        <div class="notice-child-wrapper"></div>
                                    </div>
                                    <button class="btn btn-primary save-btn-edit" @click="alert('Successfully saved Your Notification setting')">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>
<script setup>
import { ref } from "vue"
import { useToast } from "primevue/usetoast";
import FormData from "form-data";
import { useRouter } from "vue-router";


const router = useRouter()
const profFile = ref(null)
const bannerFile = ref(null)
const publicKey = ref('')
const profSelectedImage = ref(null)
const coverSelectedImage = ref(null)
publicKey.value = sessionStorage.getItem('userPublicKey')
const toast = useToast()




// function
const uploadProfilePic = async() =>{
  const url = `${store.backendUrl}/users/uploadProfilePic`;
  const token = sessionStorage.getItem("accessToken")
  if(!token){
    toast.add({severity:'error', summary: 'Error', detail:'Unauthorized', group: 'br', life: 3000});
    return
  }
  let form = new FormData()
  form.append('profilePic',profFile.value)
  form.append('bannerPic',bannerFile.value)
  const config = {
      headers: {
          authorization: 'Bearer ' + token,
      }
  }
  axios.post(url,form ,config).then(res=>{
    if(res.status===201){
      toast.add({severity:'success', summary: "Success", detail:'Updated Successfully', group: 'br', life: 3000});
      router.push(`/profile/${publicKey.value}`)
    }
  }).catch(e=>{
      toast.add({severity:'error', summary: e.response.statusText, detail:'No changes were made', group: 'br', life: 3000});
    })
        
  }

const getUser = () =>{
    axios.get(`${backend.url}/users/owner/${publicKey.value}`).then((res)=>{
    let profImg = res.data.profilePic == null? null:`${store.imageUrl}/${res.data.profilePic.s3Uri}`
    let bannerImg = res.data.bannerPic == null? null:`${store.imageUrl}/${res.data.bannerPic.s3Uri}`
    if(profImg){
        profSelectedImage.value = profImg;
    }
    if(bannerImg){
        coverSelectedImage.value = bannerImg;
    }
    }).catch(e=>{
            toast.add({severity:'error', summary: e.response.statusText, detail:e.response.data.message, group: 'br', life: 3000});
            router.push("/404")
  })
}

  const profImageChange= (e) => {
    if (e.target.files && e.target.files.length > 0) {
      profFile.value = e.target.files[0];
      profSelectedImage.value = URL.createObjectURL(profFile.value);
    }
  }

  const coverImageChange= (e) => {
    if (e.target.files && e.target.files.length > 0) {
      bannerFile.value = e.target.files[0];
      coverSelectedImage.value = URL.createObjectURL(bannerFile.value);
    }
  }


getUser()
    
</script>
<script>
    import Layout from "@/components/layouts/Layout";
    import Breadcrumb from "@/components/breadcrumb/Breadcrumb";
    import {loginState} from "../auth/auth";
import { store } from "../store/store";
import { useToast } from "primevue/usetoast";
import { backend } from "../store/storeUrl";
import axios from "axios";
    export default {
        name: 'EditProfilePage',
        components: {Breadcrumb, Layout},
        methods: {
            previewImage(e, id) {
                if (e.target.files && e.target.files.length > 0) {
                    const file = e.target.files[0];
                    document.querySelector(`#${id}`).setAttribute('src', URL.createObjectURL(file));
                }
            }
        }
    }
</script>