<template>
    <router-view/>
    <Toast/>
    <Toast position="bottom-right" group="br" />
</template>

<script setup>
    import { useToast } from "primevue/usetoast";
    import { getCurrentInstance } from 'vue';
    
    const toast = useToast()
    const internalInstance = getCurrentInstance()
    const emitter = internalInstance.appContext.config.globalProperties.emitter
    emitter.on('reconnect',()=>{
        toast.add({severity:'error', summary: "Session expired", detail: "Please reconnect your wallet.", group: 'br', life: 3000});
    })
</script>

<script>
    import AppFunctions from './helpers/AppFunctions';
    import Toast from 'primevue/toast';
import { getCurrentInstance } from "vue";
    export default {
        data() {
            return {
                AppFunctions
            }
        },
        components:{
            Toast
        },
        beforeMount() {
            this.AppFunctions.addClass('body', 'active-dark-mode');
        },
        watch: {
            '$route': function (value) {
                if (value.name === 'HomePageFive') {
                    this.AppFunctions.addClass('body', 'with-particles');
                } else if (value.name === 'HomePageSeven' || value.name === 'HomePageEight') {
                    this.AppFunctions.addClass('body', 'home-sticky-pin');
                    this.AppFunctions.addClass('body', 'sidebar-header');
                    this.AppFunctions.addClass('body', 'scrollspy-example');
                    this.AppFunctions.addClass('body', 'position-relative');
                } else {
                    const body = document.querySelector('body');

                    if (body.classList.contains('with-particles')) {
                        this.AppFunctions.removeClass('body', 'with-particles');
                    }
                    if (body.classList.contains('home-sticky-pin')) {
                        this.AppFunctions.removeClass('body', 'home-sticky-pin');
                    }
                    if (body.classList.contains('sidebar-header')) {
                        this.AppFunctions.removeClass('body', 'sidebar-header');
                    }
                    if (body.classList.contains('scrollspy-example')) {
                        this.AppFunctions.removeClass('body', 'scrollspy-example');
                    }
                    if (body.classList.contains('position-relative')) {
                        this.AppFunctions.removeClass('body', 'position-relative');
                    }
                }
            }
        },
    }
</script>
