<template>
    <layout>
        <breadcrumb title="Sign Up" current="Sign Up"/>

        <div class="login-area rn-section-gapTop">
            <div class="container">
                <div class="row g-5">
                    <div class="offset-2 col-lg-4 col-md-6 ml_md--0 ml_sm--0 col-sm-12">
                        <div class="form-wrapper-one registration-area">
                            <h4>Sign up</h4>
                            <form>
                                <div class="mb-5">
                                    <label for="firstName" class="form-label">Username</label>
                                    <input type="text" id="firstName" v-model="userName">
                                </div>
                                <div class="mb-5">
                                    <label for="exampleInputEmail1" class="form-label">Email address</label>
                                    <input type="email" id="exampleInputEmail1" v-model="email">
                                </div>
                            </form>
                            <button class="btn btn-primary mr--15" @click="register()">Sign Up</button>
                                <!-- <router-link to="/login" class="btn btn-primary-alta">Log In</router-link> -->
                        </div>
                    </div>
                    <!-- <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="social-share-media form-wrapper-one">
                            <h6>Another way to sign up</h6>
                            <p> Lorem ipsum dolor sit, amet sectetur adipisicing elit.cumque.</p>
                            <button class="another-login login-facebook">
                                <img class="small-image" :src="require(`@/assets/images/icons/google.png`)" alt="">
                                <span>Log in with Google</span>
                            </button>
                            <button class="another-login login-facebook">
                                <img class="small-image" :src="require(`@/assets/images/icons/facebook.png`)" alt="">
                                <span>Log in with Facebook</span>
                            </button>
                            <button class="another-login login-twitter">
                                <img class="small-image" :src="require(`@/assets/images/icons/tweeter.png`)" alt="">
                                <span>Log in with Twitter</span>
                            </button>
                            <button class="another-login login-linkedin">
                                <img class="small-image" :src="require(`@/assets/images/icons/linkedin.png`)" alt="">
                                <span>Log in with linkedin</span>
                            </button>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </layout>
</template>
<script setup>
    import {backend} from '../store/storeUrl'
    import axios from 'axios';
    import {onMounted, ref, watch} from 'vue'
    import { useToast } from "primevue/usetoast";
    import { isConnected, loginState } from '../auth/auth';
    import { useRouter } from 'vue-router';

    const router = useRouter()
    const userName = ref('')
    const email = ref('')
    const toast = useToast();
    const publicKey = ref('')
    const signature = ref('')

    
    publicKey.value = sessionStorage.getItem('userPublicKey')
    signature.value = sessionStorage.getItem('signature')

    const getSessionId = async() =>{
        return new Promise((resolve,reject) =>{
            fetch(`${backend.url}/auth/getChallenge`,{
                method: 'GET',
                mode:'cors',
                credentials: 'include',
            }).then(res=>{
                res.text().then(message =>{
                    const secretMsg = 'Welcome to NFT Legions. Please sign the message below to verify your identity. Your custom challenge is '
                    resolve(message.replace(secretMsg,'').replace('.','').trim())
                })
            }).catch(err=>{
                reject(err)
            })
        })
    }

    const login = ()=>{
        let data = {
            publicKey: publicKey.value,
            signature: signature.value
        }
        fetch(`${backend.url}/auth/login`,{
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers:{
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then((res)=>{
            
            if(res.status === 201){
                res.json().then(res=>{
                    sessionStorage.setItem('accessToken', res.access_token)
                    loginState.value = true
                    router.push(`/profile/${publicKey.value}`)
                })
            }
            if(res.status==403){
                toast.add({severity:'error', summary: "Session expired", detail: "Please reconnect your wallet.", group: 'br', life: 3000});
            }
        }).catch(e=>{
        })
    }
    const register = async() => {
        let sessionId = await getSessionId()
        if(!publicKey.value || !signature.value){
            toast.add({severity:'error', summary: "Unauthorized", detail:"User's credentials are missing", group: 'br', life: 3000});
            return
        }
        let data = {
            username: userName.value,
            email: email.value,
            walletPublicKey: publicKey.value,
            signature: signature.value,
            oauth_id: '',
            sessionId: sessionId
        }
        fetch(`${backend.url}/users/create`,{
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers:{
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(async(res)=>{
            if(res.status == 201){
                login()
            }
            if(res.status == 400){
                let data = await res.json()
                toast.add({severity:'error', summary: res.statusText , detail: isArray(data.message)? data.message[0] : data.message  , group: 'br', life: 3000});
            }
        }).catch(e=>{
            toast.add({severity:'error', summary: e.response.statusText, detail:e.response.data.message, group: 'br', life: 3000});
        })
    }

    watch(loginState,n=>{
        if(n && isConnected.value){
            router.push(`/profile/${publicKey.value}`)
        }else if(!n && !isConnected.value){
            console.log(isConnected.value)
            router.push(`/`);
        }
    },{immediate:true})

   

</script>
<script>
    import Layout from "@/components/layouts/Layout";
    import Breadcrumb from "@/components/breadcrumb/Breadcrumb";
import { isArray } from '@vue/shared';



    export default {
        name: 'Signup',
        components: {Breadcrumb, Layout}
    }
</script>
