<template>
    <div class="time">
    <vue-countdown :time="(lockDuration-60) * 1000" v-slot="{ minutes, seconds }">
            Time Remaining：{{ minutes }} minutes, {{ seconds }} seconds.
    </vue-countdown>
    </div>
</template>
<script setup>
import VueCountdown from '@chenfengyuan/vue-countdown';
import { useInterval } from '@vueuse/core'
import { useRouter } from 'vue-router';
import {ref, watch} from 'vue'
import {store} from "../../store/store"

const counter = useInterval(1000);
const publicKey = ref('')
publicKey.value = sessionStorage.getItem('userPublicKey')
const router = useRouter()
let lockDuration = store.lockDuration


watch(counter, e=>{
    if(counter.value === (lockDuration-60)){
    router.push(`/profile/${publicKey.value}`)
}
})

</script>
<style>
    .time{
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>