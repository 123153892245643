<template>
<div class="mainmenu-nav d-xl-block">
    <ul class="mainmenu">
        <li v-for="(item, index) in navItems"
            class="has-droupdown has-menu-child-item icon-box"
            :key="`nav-item-${index}`">
            <div class="icon-box">
               <button class="btn btn-primary-alta btn-small">Connected</button>              
            </div> 
            <ul v-if="item.hasDropdown" class="submenu" @click="removeSessionStorage()" style="margin-top:22.5px; cursor:pointer">
                <div>
                    <div style="padding: 10px; display: flex; align-items: center; justify-content: space-between;">
                        Disconnect
                        <img src="../../../assets/images/icons/images.svg" style="width:15px;height:15px;background-color:white;">
                    </div>
                </div>
            </ul>
        </li>
    </ul>
    </div>
</template>

<script setup>
import {ref} from 'vue'
import { NetworkType, PublicAccount } from "tsjs-xpx-chain-sdk"
import { getCurrentInstance} from "vue";
import { isConnected, loginState } from '../../../auth/auth';
const address = ref('')
const signature = ref('')
const internalInstance = getCurrentInstance()
const emitter = internalInstance.appContext.config.globalProperties.emitter
const navItems = ref([
                {
                    link: '#',
                    title: 'Connected',
                    hasDropdown: true,
                    megamenu: false,
                    dropdownItems: [
                        {link:"/" ,title: 'Disconnect', icon: 'home'},
                    ]
                },
            ])

const fetchSessionStorage = () =>{
  const publicKey = sessionStorage.getItem('userPublicKey')
  const signatured = sessionStorage.getItem('signature')
  if(publicKey!=null && signature!=null){
    address.value = PublicAccount.createFromPublicKey(publicKey, NetworkType.TEST_NET).address.pretty()
    signature.value = signatured
  }else{
    address.value = ""
    signature.value = ""
  }
}

const removeSessionStorage = () =>{
  sessionStorage.removeItem('userPublicKey')
  sessionStorage.removeItem('signature')
  sessionStorage.removeItem('accessToken')
  emitter.emit('Mobile Wallet Disconnected')
  loginState.value = false
  isConnected.value = false
  fetchSessionStorage()
}
</script>