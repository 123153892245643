<template>
  <layout>
    <breadcrumb title="Create a New File" current="Create a New File" />

    <div class="create-area rn-section-gapTop">
      <div class="container">
        <div class="row g-5">
          <div class="col-lg-3 offset-1 ml_md--0 ml_sm--0">
            <!-- Start file Upload Area -->
            <div class="upload-area">
              <div class="upload-formate mb--30">
                <h6 class="title">Upload file</h6>
                <p class="formate">Drag or choose your file to upload</p>
                <p class="formate">We only accept PNG, GIF, WEBP, MP4 or MP3. Max 1GB.</p>
              </div>
              <div class="brows-file-wrapper">
                
                <input
                  id="file"
                  type="file"
                  class="inputfile"
                  :disabled = "disabled"
                  @change="imageChange"
                />
                <img
                  v-if="selectedImage"
                  id="createfileImage"
                  :src="selectedImage"
                  alt=""
                  data-black-overlay="6"
                />
                <label for="file" title="No File Chosen">
                  <i v-if="!selectedImage" class="feather-upload" />
                    
                    
                </label>
              </div>
            </div>
            <!-- End File Upload Area -->

            <div class="mt--100 mt_sm--30 mt_md--30 d-none d-lg-block">
              <h5>Note:</h5>
              <span> Service fee : <strong>2.5%</strong> </span> <br />
              <span>
                You will receive : <strong>25.00 ETH $50,000</strong></span
              >
            </div>
          </div>

          <div class="col-lg-7"> 
            <div class="form-wrapper-one">
              <p class="connect" v-if="disabled == 1">Please Connect Wallet To Create<sup>*</sup></p>
                <p class="connect" v-if="disabled == 2">Please Activate Account<sup>*</sup></p>
              <form class="row" action="#">
                <div class="col-md-12">
                  <div class="input-box pb--20">
                    <label for="name" class="form-label">Name</label>
                    <input
                      id="name"
                      placeholder="e. g. `Digital Awesome Game`" :disabled = "disabled" v-model="nftName"
                    />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="input-box pb--20">
                    <label for="Description" class="form-label"
                      >Description</label
                    >
                    <textarea
                      id="Description"
                      rows="3"
                      placeholder="e. g. “After purchasing the product you can get item...”"
                      :disabled = "disabled"
                      v-model="nftDescription"
                    >
                    </textarea>
                  </div>
                </div>
                <!-- <div class="col-md-4">
                                    <div class="input-box pb--20">
                                        <label for="dollerValue" class="form-label">Item Price in $</label>
                                        <input id="dollerValue" placeholder="e. g. `20$`">
                                    </div>
                                </div> -->
                <div>
                    Properties
                </div>
                <div
                  class="col-md-12"
                  style="display: flex; justify-content: space-around; align-items: center;"
                  v-for="(atrributeName, index) of attributeNames" :key="index"
                >
                <div class="logo-thumbnail logo-custom-css">
                    <img src="../assets/images/icons/icon-delete-red.svg" @click="removeProperty(index)" />
                  </div>
                    <div class="input-box pb--20">
                    <label for="Size" class="form-label">Property</label>
                    <input id="Size" placeholder="e. g. `Size`" v-model="attributeNames[index]" :disabled = "disabled"/>
                  </div>
                  <div class="input-box pb--20">
                    <label for="Properties" class="form-label"
                      >Value</label
                    >
                    <input id="Properties" placeholder="e. g. `Properties`" v-model="attributeValues[index]" :disabled = "disabled"/>
                  </div>
                </div>
                <div>
                    <a class="btn btn-primary btn-large w-100" style="margin-bottom: 15px;" v-if="!disableAdd" @click="addProperty()">
                       Add Property 
                    </a>
                </div>
                <!-- <div class="col-md-4 col-sm-4">
                  <div class="input-box pb--20 rn-check-box">
                    <input
                      class="rn-check-box-input"
                      type="checkbox"
                      id="putonsale"
                    />
                    <label class="rn-check-box-label" for="putonsale">
                      Put on Sale
                    </label>
                  </div>
                </div>
                <div class="col-md-4 col-sm-4">
                  <div class="input-box pb--20 rn-check-box">
                    <input
                      class="rn-check-box-input"
                      type="checkbox"
                      id="instantsaleprice"
                    />
                    <label class="rn-check-box-label" for="instantsaleprice">
                      Instant Sale Price
                    </label>
                  </div>
                </div>
                <div class="col-md-4 col-sm-4">
                  <div class="input-box pb--20 rn-check-box">
                    <input
                      class="rn-check-box-input"
                      type="checkbox"
                      id="unlockpurchased"
                    />
                    <label class="rn-check-box-label" for="unlockpurchased">
                      Unlock Purchased
                    </label>
                  </div>
                </div> -->
                <div class="col-md-12 col-xl-4">
                  <!-- <div class="input-box">
                    <button
                      type="button"
                      class="btn btn-primary-alta btn-large w-100"
                      data-bs-toggle="modal"
                      data-bs-target="#uploadModal"
                    >
                      Preview
                    </button>
                  </div> -->
                </div>
                <div class="col-md-12 col-xl-8 mt_lg--15 mt_md--15 mt_sm--15">
                  <div class="input-box">
                    
                  </div>
                </div>
              </form>
              <div>
                <button class="btn btn-primary btn-large w-100" :disabled="disableButton" @click="createNft()">
                      Submit Item
                    </button>
              </div>
            </div>
          </div>
          <div class="mt--100 mt_sm--30 mt_md--30 d-block d-lg-none">
            <h5>Note:</h5>
            <span> Service fee : <strong>2.5%</strong> </span> <br />
            <span> You will receive : <strong>25.00 ETH $50,000</strong></span>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div
      class="rn-popup-modal upload-modal-wrapper modal fade"
      id="uploadModal"
      tabindex="-1"
      aria-hidden="true"
    >
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
      >
        <i class="feather-x" />
      </button>
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content share-wrapper">
          <div class="modal-body">
            <product-card
              :product-date="product"
              product-style-class="no-overlay"
            />
          </div>
        </div>
      </div>
    </div>

    <share-modal />

    <report-modal />
  </layout>
</template>
<script setup>
import {ref, computed, watch, onMounted} from 'vue'
import FormData from "form-data";
import { useToast } from "primevue/usetoast";
import axios from "axios";
import { useRouter } from "vue-router";

  const router = useRouter()
  const selectedImage = ref(null)
  const toast = useToast()
  const file = ref(null)
  const fileName = ref('')
  const nftName = ref('')
  const nftDescription = ref('')
  const attributeNames = ref([''])
  const attributeValues = ref([''])
  const disabled = ref(0)

  const addProperty = () =>{
      attributeNames.value.push('')
      attributeValues.value.push('')
  }

  const removeProperty = (i) => {
      attributeNames.value.splice(i, 1);
      attributeValues.value.splice(i, 1)
  }

  const getAttributeObject = () =>{
      return attributeNames.value.reduce((key,value,index)=>{
          return {...key, [value]: attributeValues.value[index]};
      },{})
  }

  const disableAdd = computed(()=>{
    return !attributeNames.value.every(name=>name!='') || !attributeValues.value.every(name=>name!='')
  })

  const disableButton = computed(()=>{
    return !attributeNames.value.every(name=>name!='') || !attributeValues.value.every(name=>name!='') || file.value === null || nftName.value === "" || nftDescription.value === ""
  })



  const imageChange= (e) => {
    if (e.target.files && e.target.files.length > 0) {
      file.value = e.target.files[0];
      fileName.value = file.value.name
      selectedImage.value = URL.createObjectURL(file.value);
    }
  }
  const escrowPublicKey = ref('')
  const publicKey = ref('')
  publicKey.value = sessionStorage.getItem('userPublicKey')

  const isActivated = async() =>{
    if(!publicKey.value){
      disabled.value = 1
      return false
    }
    const res = await axios.get(store.backendUrl + "/users/owner/" + publicKey.value)
    if(res.status == 200){
      if(res.data.escrowPublicKey!=null){
        escrowPublicKey.value = res.data.escrowPublicKey
        disabled.value = false
        return true
      }
      disabled.value = 2    
      return false
    }
    return false
  }
 
  onMounted(async()=>{
    await isActivated()
  })

  watch(loginState,n=>{
    if(n==false){
      router.push('/')
    }
  })

  const createNft = async() =>{
  if(!escrowPublicKey.value){
    return
  }
  const url = 'https://nftm-backend.proximaxtest.com/nft/create';
  const token = sessionStorage.getItem("accessToken")
  if(!token){
    toast.add({severity:'error', summary: 'Error', detail:'Unauthorized', group: 'br', life: 3000});
    return
  }
  let form = new FormData()
  form.append('file',file.value)
  form.append('name',nftName.value)
  form.append('description',nftDescription.value)
  form.append('properties',JSON.stringify(getAttributeObject()))
  const config = {
      headers: {
          authorization: 'Bearer ' + token,
      }
  }
  axios.post(url,form ,config).then(res=>{
    if(res.status===201){
      toast.add({severity:'success', summary: "Success", detail:'NFT successfully created', group: 'br', life: 3000});
      clearInput()
      router.push(`/product/verify/${res.data.id}`)
    }
  }).catch(e=>{
      toast.add({severity:'error', summary: e.response.statusText, detail:e.response.data.message, group: 'br', life: 3000});
    })
        
  }

  const clearInput = ()=>{
    file.value = null
    nftName.value = ''
    nftDescription.value = ''
    attributeNames.value = ['']
    attributeValues.value = ['']
    selectedImage.value = null
  } 
</script>
<script>
import Layout from "@/components/layouts/Layout";
import Breadcrumb from "@/components/breadcrumb/Breadcrumb";
import ProductCard from "@/components/product/ProductCard";
import ShareModal from "@/components/modal/ShareModal";
import ReportModal from "@/components/modal/ReportModal";
import { loginState } from "../auth/auth";
import { store } from "../store/store";

export default {
  name: "CreateProduct",
  components: { ReportModal, ShareModal, ProductCard, Breadcrumb, Layout },
  data() {
    return {
      product: {
        id: 1,
        productImage: require(`@/assets/images/portfolio/portfolio-01.jpg`),
        authors: [
          {
            id: 1,
            name: "Jone lee",
            image: require(`@/assets/images/client/client-1.png`),
          },
          {
            id: 2,
            name: "Jone lee",
            image: require(`@/assets/images/client/client-2.png`),
          },
          {
            id: 3,
            name: "Nisat Tara",
            image: require(`@/assets/images/client/client-3.png`),
          },
        ],
        biddingAmount: "9",
        productName: "Preatent",
        latestBid: "Highest bid 1/20",
        lastBid: "0.244wETH",
        reacted: "322",
      },
    };
  },
};
</script>
<style scoped>
  .connect{
    color: red;
    font-size: 14px;
  }

</style>
