<template>
<Layout>
    <div v-if="bannerPic" :style="{backgroundImage: `url(${bannerPic})` }" class="rn-author-bg-area bg_image ptb--150">
        <div class="container">
            <div class="row"></div>
        </div>
    </div>
    <div v-else class="rn-author-bg-area bg_image--9 bg_image ptb--150">
        <div class="container">
            <div class="row"></div>
        </div>
    </div>

    <!-- Start of collection page -->
    

    <div class="rn-author-area mb--30 mt_dec--120">
        <div class="container">
            <div class="row padding-tb-50 align-items-center d-flex">
                <div class="col-lg-12">
                    <div class="author-wrapper">
                        <div class="author-inner" style="max-width: 100px; max-height:100px; height: 100px;">
                            <div class="user-thumbnail">
                                        <img style="max-width: 200px; border-radius: 50%; border-width: 8px; border-color: black;"
                                            :src="`${imgUrl}/${collectionData.profilePic.s3Uri}`"
                                            alt="Nft_Profile"
                                            v-if="collectionData"
                                        />
                                
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Collection Details -->
                <div class="col-lg-5 col-md-12 col-sm-12 mt_md--50 mt_sm--60" style="margin-left:auto;margin-right:auto; text-transform:uppercase;">
                    <div class="rn-pd-content-area">
                        
                        <div style="display:flex ; flex-direction: column; margin-top: 70px;" >

                            <!-- Collection Name -->
                            <h2 class="title" style="margin-left:auto; margin-right:auto; margin-bottom: 4px;" v-if="collectionData">{{ collectionData.name }}</h2>

                            <!-- Collection Owner -->
                            <div v-if="collectionData" style="margin-left:auto; margin-right:auto; margin-bottom: 20px;">
                                <router-link :to="`/profile/${collectionData.owner.walletPublicKey}`">
                                    <p v-if="collectionData" style="font-size: small;">
                                        by {{ collectionData.owner.username }}
                                    </p>
                                </router-link>
                            </div>

                            <!-- Collection Description -->
                            <div class="color-white" style="margin-bottom: 20px; max-width: fit-content; word-break: break-all; margin-left:auto; margin-right:auto" v-if="collectionData">
                                {{ collectionData.description}}
                            </div>
                            
                            <!-- Statistics -->
                            <div class="color-white" style="margin-left:auto; margin-right:auto; margin-bottom: 20px; font-size: large;">
                                ITEMS: <strong>{{supply}}</strong> &#x2022; OWNERS: <strong>{{owners}}</strong> &#x2022; CREATION DATE: <strong>{{creationDate}}</strong>
                            </div>

                            <!-- Verify Collection -->
                            <div class="button-div" style="margin-left:auto; margin-right:auto;" >
                                <button class="btn btn-primary-alta" style="margin-bottom: 20px;"
                                @click="verifyCollection()" v-if="isUser && verifiedState == 0">
                                    Verify Collection
                                </button>
                                <button class="btn btn-primary-alta" style="margin-bottom: 20px;"
                                    v-if="isUser && verifiedState == 1">
                                    Verify In Progress
                                </button>
                                <button class="btn btn-primary-alta" style="margin-bottom: 20px;"
                                v-if="isUser && verifiedState == 2">
                                    Add NFT
                                </button>
                            </div>

                            <!-- <div class="tab-pane row g-5 d-flex fade show active"
                                id="nav-profile"
                                role="tabpanel"
                                aria-labelledby="nav-profile-tab">
                                <div class="col-5 col-lg-4 col-md-6 col-sm-6 col-12"
                                    v-for="(product, index) in nft"
                                    :key="`product-item-${index}`">
                                    <ProductCardOwn
                                        :product-date="product"
                                        product-style-class="no-overlay with-placeBid"
                                        :show-place-bid="false"
                                    />
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</Layout>
</template>

<script setup>
import Layout from "@/components/layouts/Layout";
import { store } from "../store/store";
import axios from "axios";
import { computed, onMounted, ref } from "vue";
import { useToast } from "primevue/usetoast";
import { loginState } from "../auth/auth";
import { useRouter } from "vue-router";
import ProductCardOwn from "../components/product/ProductCardOwn.vue"

const props = defineProps({
    id: String
})
const imgUrl = store.imageUrl
const verifiedState = ref(null)
const collectionData = ref(null)
const bannerPic = ref('')
const toast = useToast()
const router = useRouter()
const supply = ref(0)
const owners = ref(0)
const creationDate = ref(0)
const nft = ref([])
let publicKey = sessionStorage.getItem("userPublicKey");

const isUser = computed(()=>{
    if(!loginState.value){
        return false
    }
    if(!collectionData.value){
        return false
    }
    if(collectionData.value.owner.walletPublicKey == publicKey){
        verifiedState.value = 2
        return true
    }
    return false
})


const getCollectionDetails = ()=>{
    axios.get(`${store.backendUrl}/collection/id/${props.id}`).then((res)=>{
        collectionData.value = res.data
        verifiedState.value = res.data.state
    }).catch(e=>{
        toast.add({severity:'error', summary: "Error", detail:"error", group: 'br', life: 3000});
    })
}

const addNFT = () => {
    router.push(`/AddNft`)
}

const collectionNft = () => {
    axios.get(`${store.backendUrl}/collection/owner/${publicKey}`).then((res)=>{
        nft.value = res.data
    }).catch(e=>{
        toast.add({severity:'error', summary: e.response.statusText, detail:e.response.data.message, group: 'br', life: 3000});
    })
}

collectionNft()

const verifyCollection = () =>{
    router.push(`/collection/verify/${props.id}`)
}

onMounted(()=>{
    getCollectionDetails()
})
</script>

