<template>
    <router-link :to="{ name: 'Collection Details', params: { id: collection.id }}">
    <div :class="`product-style-one ${productStyleClass}`">
        <div class="card-thumbnail image-div">
            <div class="image-contain">
                <!-- <router-link :to="{ name: 'ProductDetails', params: { id: collection.id }}" > -->
                <img :src="`${url}/${collection.profilePic.s3Uri}`" @load="$emit('handleImageLoad')">
            <!-- </router-link> -->
            </div>
        </div>
        <div class="product-share-wrapper">
            <div class="profile-share">
              
            </div>
            <div class="share-btn share-btn-activation dropdown">
                <button class="icon" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="feather-more-horizontal"/>
                </button>
                <div class="share-btn-setting dropdown-menu dropdown-menu-end">
                    <button type="button"
                            class="btn-setting-text share-text"
                            data-bs-toggle="modal"
                            data-bs-target="#shareModal">
                        Share
                    </button>
                    <button type="button"
                            class="btn-setting-text report-text"
                            data-bs-toggle="modal"
                            data-bs-target="#reportModal">
                        Report
                    </button>
                </div>
            </div>
        </div>
        <router-link :to="{ name: 'Collection Details', params: { id: collection.id }}">
            <span class="product-name">{{ collection.name }}</span>
        </router-link>
    </div>
</router-link>
</template>
<script setup>
import { backend } from '../../store/storeUrl';

const url = backend.imageUrl
let props = defineProps({
    collection: Object,
    productStyleClass: String
})

</script>
<script>
    import Countdown from '@/components/product/Countdown';
    
    export default {
        name: 'ProductCard',
        components: {Countdown},
        props: {
           
            showPlaceBid: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            '$route': function (to, from) {
                this.closeModal()
            }
        },
        methods: {
            closeModal() {
                const modals = document.querySelectorAll('.modal-backdrop');
                if (modals.length) {
                    modals.forEach(item => {
                        item.remove();
                    })
                }
            }
        },
    }
</script>
<style scoped>
 .image-div{
        object-fit: contain;
    }

    .image-contain{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 229px;
    }

</style>
