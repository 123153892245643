<template>
  <layout>
    <TimerCountdownVue/>
    <div class="d-flex" style="justify-content: center; margin-top: 15px">
      <transition
        enter-active-class="animate__animated animate__fadeInDown"
        leave-active-class="animate__animated animate__fadeOutUp"
      >
        <div class="modal-popup-box">
          <div
            class="connect-bg-color text-center py-2 rounded text-lg font-weight-normal fs-2"
            style="padding: 2rem"
          >
            Verify Purchase
          </div>
          <div
            class="d-flex connect-bg-color dark:border-blue-600 dark:bg-[#171717]"
            style="
              padding-left: 1.5rem;
              padding-right: 1.5rem;
              padding-top: 0.5rem;
              padding-bottom: 6rem;
              border-bottom-width: 2px;
              --tw-border-opacity: 1;
              border-color: rgba(37, 99, 235, var(--tw-border-opacity));
              justify-content: flex-start;
              align-items: center;
            "
          >
            <div>
              <div
                class="my-6"
                style="margin-top: 1.5rem; margin-bottom: 1.5rem"
                v-html="qr"
              />
              <div class="text-gray-500 text-middle fs-3">
                Please scan the QR above with Sirius Mobile App to connect.
              </div>
            </div>
          </div>
          <div
            class="d-flex justify-content-center dark:bg-[#171717] connect-bg-color"
          ></div>
        </div>
      </transition>
    </div>
  </layout>
</template>
<script setup>
import { ref, onMounted } from "vue";
import Layout from "../components/layouts/Layout.vue";
import TimerCountdownVue from "../components/countdown/TimerCountdown.vue";
import Peer from "peerjs";
import {
  AggregateBondedTransactionBuilder,
  Deadline,
  Mosaic,
  MosaicId,
  PublicAccount,
  TransactionHttp,
  TransferTransactionBuilder,
  UInt64,
} from "tsjs-xpx-chain-sdk";
import { store } from "../store/store";
import { backend } from "../store/storeUrl";
import axios from "axios";
import { useRouter } from "vue-router";
import QRCode from "qrcode";
import { useToast } from "primevue/usetoast";
import TimerCountdown from "../components/countdown/TimerCountdown.vue";

const purchaseId = ref('')
const listingId = ref('')
const toast = useToast();
const qr = ref(null);
const currencyId = ref("");
const amount = ref(0);
const assetId = ref("");
const sellerPublicKey = ref("");
const sellerEscrowPublicKey = ref("");
const buyerPublicKey = ref("");
const buyerEscrowPublicKey = ref("");
const divisibility = ref(0);
const router = useRouter();
const token = ref('')
token.value = sessionStorage.getItem("accessToken")
const config = {
  headers: {
          authorization: 'Bearer ' + token.value,
      }
}


const props = defineProps({
  id: String,
});

const getPrice = (upper, lower) => {
  if (!lower) {
    return upper;
  }
  return parseFloat(upper.toString() + "." + lower.toString());
};

const makePurchase = () => {
  buyerPublicKey.value = sessionStorage.getItem("userPublicKey");
  if(!buyerPublicKey.value){
    toast.add({severity: "error", summary: "User not connected", detail: "Please go back"})
    return
  }
  axios.get(`${backend.url}/directlistings/isListed/${props.id}`).then((res)=>{
    if(!res.data){
      toast.add({severity: "error", summary: "NFT is not Listed", detail: "Please go back",group: "br",life: 3000})
      return
    }
      listingId.value = res.data.id
      axios.post(`${backend.url}/purchase`,{
                listingId: listingId.value,
      },config).then((res)=>{
        purchaseId.value = res.data.id
      }).catch(e=>{
        toast.add({severity: "error", summary: e.response.status, detail: e.response.data.message, group: "br",life: 3000})
      })
  })
}



const getInfo = async () => {
  try {
    let res = await axios.get(`${backend.url}/purchase/id/${purchaseId.value}`);
    currencyId.value = res.data.directListing.currency.assetId;
    amount.value = getPrice(
      res.data.directListing.minPriceUpper,
      res.data.directListing.minPriceLower
    );
    assetId.value = res.data.directListing.nft.id;
    sellerPublicKey.value = res.data.directListing.owner.walletPublicKey;
    sellerEscrowPublicKey.value = res.data.directListing.owner.escrowPublicKey;
    buyerPublicKey.value = res.data.buyer.walletPublicKey;
    buyerEscrowPublicKey.value = res.data.buyer.escrowPublicKey;
    divisibility.value = res.data.directListing.currency.divisibility;
  } catch (error) {
    toast.add({
      severity: "error",
      summary: "Purchase NFT Failed",
      detail: "Cant Purchase NFT",
      group: "br",
      life: 3000,
    });
  }
};

const purchase = async (
  currencyId,
  amount,
  assetId,
  sellerPublicKey,
  sellerEscrowPublicKey,
  buyerPublicKey,
  buyerEscrowPublicKey
) => {
  //currencyId :currencyId of the listing(xpx)
  //amount: amount sold for the listing
  //assetId: asset id of the listing
  const sellerPublicAccount = PublicAccount.createFromPublicKey(
    sellerPublicKey,
    store.networkType
  );
  const sellerEscrowPublicAccount = PublicAccount.createFromPublicKey(
    sellerEscrowPublicKey,
    store.networkType
  );
  const buyerPublicAccount = PublicAccount.createFromPublicKey(
    buyerPublicKey,
    store.networkType
  );
  const buyerEscrowPublicAccount = PublicAccount.createFromPublicKey(
    buyerEscrowPublicKey,
    store.networkType
  );

  const transferTxnBuilder = new TransferTransactionBuilder();
  const buyerToSellerTxn = transferTxnBuilder
    .deadline(Deadline.create())
    .recipient(sellerPublicAccount.address)
    .networkType(store.networkType)
    .mosaics([
      new Mosaic(
        new MosaicId(currencyId),
        UInt64.fromUint(amount * Math.pow(10, divisibility.value))
      ),
    ])
    .build();

  const sellerToBuyerTxn = transferTxnBuilder
    .deadline(Deadline.create())
    .recipient(buyerEscrowPublicAccount.address)
    .networkType(store.networkType)
    .mosaics([new Mosaic(new MosaicId(assetId), UInt64.fromUint(1))])
    .build();

  let innerTx = [
    buyerToSellerTxn.toAggregate(
      PublicAccount.createFromPublicKey(
        buyerPublicAccount.publicKey,
        store.networkType
      )
    ),
    sellerToBuyerTxn.toAggregate(
      PublicAccount.createFromPublicKey(
        sellerEscrowPublicAccount.publicKey,
        store.networkType
      )
    ),
  ];

  let aggregateTxBuilder = new AggregateBondedTransactionBuilder();
  const aggregateTx = aggregateTxBuilder
    .deadline(Deadline.create())
    .innerTransactions(innerTx)
    .networkType(store.networkType)
    .build();
    const peer = new Peer(); 
    peer.on("open", async () => {
        const data = {
            payload: peer.id,
            type:'reqPeerID',
            generationHash: store.generationHash, //testnet2
            recvId: peer.id
        } 
        qr.value = await QRCode.toString(JSON.stringify(data))
    })
    peer.on("connection", (conn) => {
        conn.on("data", async (data) => {
            let payload = {
                type: 'txn',
                payload: aggregateTx.serialize()
            }
            if(data == 'requestTxnSigning'){
                conn.send(payload) 
            }else if(data === 'txnHash'){
                conn.send("success"); 
            }else{
                const transactionHttp = new TransactionHttp(store.testApiEndPoint);
                await sleep(2000);
                let res = await transactionHttp.getTransactionStatus(data).toPromise();
                if (!res) {
                    await sleep(2000);
                    let res = await transactionHttp
                    .getTransactionStatus(txnHash)
                    .toPromise();
                    if (!res) {
                    toast.add({
                        severity: "error",
                        summary: "Failed to save transaction hash",
                        detail: "Transaction hash is not known by network",
                        group: "br",
                        life: 3000,
                    });
                    }
                    if (res.status == "Success" && res.group == "partial") {
                    postTxnHash(data);
                    }
                    return;
                }
                if (res.status == "Success" && res.group == "partial") {
                    postTxnHash(data);
                }
            }
        });
    });
};

const sleep = (milliseconds) => {
  return new Promise((resolve) => {
    setTimeout(resolve, milliseconds);
  });
};

const postTxnHash = (txnHash) => {
  const config = {
    headers: {
      authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    },
  };
  const data = {
    txnHash: txnHash,
    purchaseId: purchaseId.value
  };
  const url = store.backendUrl + "/txn/purchase";
  axios
    .post(url, data, config)
    .then((res) => {
      if (res.status === 201) {
        toast.add({
          severity: "success",
          summary: "Success",
          detail: "Please wait for admin to approve",
          group: "br",
          life: 3000,
        });
        router.push(`/profile/${buyerPublicKey.value}`);
      }
    })
    .catch((e) => {
      toast.add({
        severity: "error",
        summary: e.response.statusText,
        detail: e.response.data.message,
        group: "br",
        life: 3000,
      });
    });
};

onMounted(async () => {
  makePurchase()
  await sleep(2000)
  await getInfo();
  await sleep(4000)
  purchase(
    currencyId.value,
    amount.value,
    assetId.value,
    sellerPublicKey.value,
    sellerEscrowPublicKey.value,
    buyerPublicKey.value,
    buyerEscrowPublicKey.value
  );
});
</script>
