<template>
    <Layout>
        <div class="d-flex" style="justify-content:center; margin-top: 15px;">
            <div>
        <div class="connect-bg-color text-center py-2 rounded text-lg font-weight-normal fs-2" style="padding:2rem;">Mint Collection</div>
        <div class="d-flex connect-bg-color dark:border-blue-600 dark:bg-[#171717]" style="padding-left: 1.5rem; padding-right: 1.5rem;padding-top: 0.5rem;padding-bottom: 6rem;border-bottom-width: 2px;	--tw-border-opacity: 1;border-color: rgba(37, 99, 235, var(--tw-border-opacity));justify-content: flex-start; align-items: center;">
            <div v-if="step==1">
                <div class="my-6" style="margin-top: 1.5rem;margin-bottom: 1.5rem; width: 100%;" v-html="qr"/>
                <div class="text-gray-500 text-middle fs-3">Scan the QR above to import an account for collection.</div>
                
            </div>
            <div v-else>
                <div class="my-6" style="margin-top: 1.5rem;margin-bottom: 1.5rem; width: 100%;" v-html="qr2"/>
                <div class="text-gray-500 text-middle fs-3">Scan the QR above to mint your collection.</div>
            </div>
          
        </div>
        <div class="d-flex justify-content-center dark:bg-[#171717] connect-bg-color">
            <button v-if="step==1" @click="step = 2" class="my-3 py-1.5 px-6 text-center text-white rounded-xl fs-2">
                Next
            </button>
            <button v-if="step==2" @click="step = 1" class="my-3 py-1.5 px-6 text-center text-white rounded-xl fs-2">
                Back
            </button>
        </div>
      </div>
        </div>
    </Layout>
</template>

<script setup>
import Layout from "@/components/layouts/Layout";
import axios from "axios";
import { useToast } from "primevue/usetoast";
import { Account, AccountMetadataTransactionBuilder, AggregateBondedTransactionBuilder, Convert, Deadline, ModifyMultisigAccountTransactionBuilder, MultisigCosignatoryModification, MultisigCosignatoryModificationType, PublicAccount, TransactionHttp, UInt64 } from "tsjs-xpx-chain-sdk";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { store } from "../store/store";
import QRCode from 'qrcode'
import { storeAccount } from "../store/storeAccount";
import Peer from "peerjs";

const props = defineProps({
    id: String
})
const step = ref(1)
const collectionDetails = ref(null)
const toast = useToast()
const qr = ref('')
const qr2 = ref('')
const router = useRouter()
const privateKey = ref('')
const accessToken = ref( sessionStorage.getItem("accessToken"));
const userPublicKey = ref(sessionStorage.getItem('userPublicKey'))

const fetchCollectionDetails = async()=>{
    const response = await axios.get(`${store.backendUrl}/collection/id/${props.id}`)
    collectionDetails.value = response.data
    if(collectionDetails.value.state!=0){
        router.push('/')
        return
    }
}

const generateNewAccount = async() =>{
    privateKey.value = Account.generateNewAccount(store.networkType).privateKey
    qr.value = await QRCode.toString(privateKey.value)
}

const buildCollectionTxn = () =>{
    const data = {
        name: collectionDetails.value.name, 
        description: collectionDetails.value.description,
        image: `${store.backendUrl}/${collectionDetails.value.profilePic.s3Uri}`,  
    }
    const accountToConvert = Account.createFromPrivateKey(privateKey.value, store.networkType)
    const userPublicAccount = PublicAccount.createFromPublicKey(userPublicKey.value,store.networkType)
    const operatorPublicAccount = PublicAccount.createFromPublicKey(
        storeAccount.operator, 
        store.networkType
    );
    const adminPublicAccount = PublicAccount.createFromPublicKey(
        storeAccount.admin,
        store.networkType
    );
    let cosigners = [userPublicAccount, operatorPublicAccount, adminPublicAccount]
    let multisigModification = []
    for(let i = 0; i<cosigners.length;i++){
        let publicAcc = cosigners[i]
        multisigModification.push(
            new MultisigCosignatoryModification (
                MultisigCosignatoryModificationType.Add,
                publicAcc
            )
        )
    }
    const convertIntoMultisigTxn =  new ModifyMultisigAccountTransactionBuilder() 
    .deadline(Deadline.create())
    .networkType(store.networkType)
    .minApprovalDelta(2)
    .minRemovalDelta(3)
    .modifications(multisigModification)
    .build();

    const accountMetadataTxn = new AccountMetadataTransactionBuilder()
    .deadline(Deadline.create())
    .oldValue('')
    .targetPublicKey( accountToConvert.publicAccount)
    .scopedMetadataKey(UInt64.fromHex(Convert.utf8ToHex('nft.json')))
    .value(JSON.stringify(data))
    .calculateDifferences()
    .networkType(store.networkType)
    .build()

    let innerTxn = [
        convertIntoMultisigTxn.toAggregate(accountToConvert.publicAccount),
        accountMetadataTxn.toAggregate(accountToConvert.publicAccount),
    ]

    const abtTxnBuilder = new AggregateBondedTransactionBuilder();
    const abtTxn  = abtTxnBuilder
    .deadline(Deadline.create())
    .networkType(store.networkType)
    .innerTransactions(innerTxn)
    .build()
    
    const peer = new Peer(); 
    peer.on("open", async () => {
        const data = {
            payload: peer.id,
            type:'reqPeerID',
            generationHash: store.generationHash, //testnet2
            recvId: peer.id
        } 
        qr2.value = await QRCode.toString(JSON.stringify(data))
    })
    peer.on("connection", (conn) => {
        conn.on("data", async (data) => {
            let payload = {
                type: 'txn',
                payload: abtTxn.serialize()
            }
            if(data == 'requestTxnSigning'){
                conn.send(payload) 
            }else if(data === 'txnHash'){
                conn.send("success"); 
            }else{
                const transactionHttp = new TransactionHttp(store.testApiEndPoint);
                await sleep(2000);
                let res = await transactionHttp.getTransactionStatus(data).toPromise();
                if (!res) {
                    await sleep(2000);
                    let res = await transactionHttp
                    .getTransactionStatus(txnHash)
                    .toPromise();
                    if (!res) {
                    toast.add({
                        severity: "error",
                        summary: "Failed to save transaction hash",
                        detail: "Transaction hash is not known by network",
                        group: "br",
                        life: 3000,
                    });
                    }
                    if (res.status == "Success" && res.group == "partial") {
                        postTxnHash(data);
                    }
                    return;
                }
                if (res.status == "Success" && res.group == "partial") {
                    postTxnHash(data);
                }
            }
        });
    });
}

const sleep = (milliseconds) => {
  return new Promise((resolve) => {
    setTimeout(resolve, milliseconds);
  });
};

const postTxnHash = (txnHash) => {
  const config = {
    headers: {
      authorization: "Bearer " + accessToken.value,
    },
  };
  const data = {
    txnHash: txnHash,
    collectionId: props.id
  };
  const url = store.backendUrl + "/txn/collection";
  axios
    .post(url, data, config)
    .then((res) => {
      if (res.status === 201) {
        toast.add({
          severity: "success",
          summary: "Success",
          detail: "Please wait for admin to approve",
          group: "br",
          life: 3000,
        });
        router.push("/profile/" + userPublicKey.value)
      }
    })
    .catch((e) => {
      toast.add({
        severity: "error",
        summary: e.response.statusText,
        detail: e.response.data.message,
        group: "br",
        life: 3000,
      });
    });
};


onMounted(async()=>{
    try {
        await fetchCollectionDetails()
        await generateNewAccount()
        buildCollectionTxn()
        
    } catch (error) {
        router.push('/')
    }
})
</script>