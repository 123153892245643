<template>
  <div class="d-flex">
      <!-- <button v-if="address==''" @click="toggleModal=!toggleModal" class="btn btn-primary-alta btn-small">Connect</button>
    <div v-else @mouseover="toggleWallet = true" @mouseout="toggleWallet = false" class="px-3 py-1 rounded bg-blue-600 dark:bg-blue-600 flex items-middle cursor-pointer" :title="address">
      <img :src="require(`@/assets/images/wallet.svg`)" class="w-8 h-8" style="height:2rem;width:2rem;">
      <div class="text-truncate ml-1 text-white" style="width:96px;">{{address}}</div>
    </div>
    <div v-if="toggleWallet" @mouseover="toggleWallet = true" @mouseout="toggleWallet = false" class="position-relative">
      <div class="position-absolute bg-white w-100 rounded disconnect">
        <div @click="removeSessionStorage()" class="px-3 py-2 cursorDisconnect">Disconnect</div>
      </div>
    </div> -->
    <transition
      enter-active-class="animate__animated animate__fadeInDown"
      leave-active-class="animate__animated animate__fadeOutUp">
        <div class="modal-popup-box">
          <div class="connect-bg-color text-center py-2 rounded text-lg font-weight-normal fs-2" style="padding:2rem;">Connect Wallet</div>
          <div class="d-flex connect-bg-color dark:border-blue-600 dark:bg-[#171717]" style="padding-left: 1.5rem; padding-right: 1.5rem;padding-top: 0.5rem;padding-bottom: 6rem;border-bottom-width: 2px;	--tw-border-opacity: 1;border-color: rgba(37, 99, 235, var(--tw-border-opacity));justify-content: flex-start; align-items: center;">
            <div>
              <div class="my-6" style="margin-top: 1.5rem;margin-bottom: 1.5rem;" v-html="qr"/>
              <div class="text-gray-500 text-middle fs-3">Please scan the QR above with Sirius Mobile App to connect.</div>
            </div>
          </div>
          <div class="d-flex justify-content-center dark:bg-[#171717] connect-bg-color">
            <button class="my-3 py-1.5 px-6 text-center text-white rounded-xl fs-2">Learn how to connect</button>
          </div>
        </div>
    </transition>
    </div>
  </template>
  <script setup >
  import { shallowRef } from "@vue/reactivity"
  import axios from "axios";
  import { Peer } from "peerjs"
  import { useToast } from "primevue/usetoast";
  import QRCode from 'qrcode'
  import { NetworkType, PublicAccount } from "tsjs-xpx-chain-sdk"
  import { getCurrentInstance, onMounted,ref } from "vue";
  import { useRouter } from "vue-router";
  import {isConnected, loginState} from '../../auth/auth'
  import { store } from "../../store/store";
  import {backend} from "../../store/storeUrl"
  
  
  
  const toast = useToast()
  const toggleModal = shallowRef(false)
  const qr = shallowRef('')
  const address = shallowRef('')
  const signature = shallowRef('')
  const mobileQrPage = shallowRef(false)
  const publicKey = ref('')
  const router = useRouter()
  const internalInstance = getCurrentInstance()
  const emitter = internalInstance.appContext.config.globalProperties.emitter
  const secret = ref('')
  
  // const generateRandomString = (length) => {
  //   var result           = '';
  //   var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  //   var charactersLength = characters.length;
  //   for ( var i = 0; i < length; i++ ) {
  //     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  //   }
  //   return result;
  // }
  
  const getChallenge = async ()=>{
    return new Promise((resolve,reject) =>{
      fetch(`${backend.url}/auth/getChallenge`,{
          method: 'GET',
          mode:'cors',
          credentials: 'include',
      }).then(res=>{
          res.text().then(message =>{
            resolve(message)
          })
      }).catch(err=>{
          reject(err)
      })
  })
  }
  
  
  
  const generateMobileQr = async () =>{
    
    secret.value = await getChallenge()
    const peer = new Peer(); 
    peer.on("open", async()=> {
      const qrValue = {
        type: "connect",
        recvId: peer.id,
        secret: secret.value,
        origin: window.location.origin,
        generationHash: "56D112C98F7A7E34D1AEDC4BD01BC06CA2276DD546A93E36690B785E82439CA9", //testnet2
      };
      qr.value = await QRCode.toString(JSON.stringify(qrValue))
      mobileQrPage.value = true
    });
    peer.on("connection", (conn) => {
      conn.on("data", (data) => {
        const signerPubAcc = PublicAccount.createFromPublicKey(data.publicKey, store.networkType)
        const isValid = signerPubAcc.verifySignature(secret.value, data.signature)
        if (isValid) {
          conn.send("success")
          sessionStorage.setItem('userPublicKey',signerPubAcc.publicKey)
          publicKey.value = signerPubAcc.publicKey
          sessionStorage.setItem('signature',data.signature)
          fetchSessionStorage()
          emitter.emit('Mobile Wallet Connected')
          peer.disconnect()
          closeModal()
          Authenticate()
        } else {
          toast.add({severity:'error', summary: "Unauthorized", detail:"Signature doesn't match", group: 'br', life: 3000});
        }
      });
    });
  }
  
  const Authenticate = () => {
    
    let data = {
      publicKey: sessionStorage.getItem('userPublicKey'),
      signature: sessionStorage.getItem('signature')
    }
    
    fetch(`${backend.url}/auth/login`,{
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers:{
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    }).then((res)=>{
        if(res.status === 401){
          isConnected.value = true
          router.push('/sign-up')
          return
        }
        if(res.status === 201){

          res.json().then(res=>{
            sessionStorage.setItem('accessToken', res.access_token)
            axios.get(`${backend.url}/auth/profile`,{
              headers: {
                Authorization: "Bearer " + res.access_token 
              }
            }).then((res)=>{
              if(res.status === 200){
                loginState.value = true
                router.push(`/profile/${publicKey.value}`)
              }
            })
          })
        }
    }).catch(e=>{
      router.push('/sign-up')
    })
    
  }
  
  
  
  
  const fetchSessionStorage = () =>{
    const publicKey = sessionStorage.getItem('userPublicKey')
    const signatured = sessionStorage.getItem('signature')
    if(publicKey!=null && signature!=null){
      address.value = PublicAccount.createFromPublicKey(publicKey, store.networkType).address.pretty()
      signature.value = signatured
    }else{
      address.value = ""
      signature.value = ""
    }
  }
  
  const removeSessionStorage = () =>{
    sessionStorage.removeItem('userPublicKey')
    sessionStorage.removeItem('signature')
    emitter.emit('Mobile Wallet Disconnected')
    fetchSessionStorage()
  }
  onMounted(()=>{
    fetchSessionStorage()
  })
  
  
  
  const closeModal = () =>{
    toggleModal.value = false
    qr.value = ''
    mobileQrPage.value = false
  }
  
  emitter.on("Button Clicked",()=>{
    generateMobileQr()
  })
  </script>
  <style scoped>
  .modal-popup-box{
    width: 100%;
    height: 100%;
  }
  
  .mobile-button:hover{
    background-color: aqua;
    cursor: pointer;
  }
  
  p:hover{
    color: black;
  }
  </style>
  