import {createRouter, createWebHistory} from 'vue-router'
import HomePageOne from '../views/home/HomePageOne'
import CreateProduct from '../views/CreateProduct'
import AddNft from '../views/AddNft'
import ProductDetails from '../views/ProductDetails'
import Connect from '../views/Connect'
import Collection from '../views/Collection'
import Creators from '../views/Creators'
import AuthorDetails from '../views/AuthorDetails'
import EditProfilePage from '../views/EditProfilePage'
import About from '../views/About'
import Contact from '../views/Contact'
import BlogDetails from '../views/blog/BlogDetails'
import BlogCategoryPosts from '../views/blog/BlogCategoryPosts'
import BlogTagPosts from '../views/blog/BlogTagPosts'
import BlogSingleColumn from '../views/blog/BlogSingleColumn'
import ExploreOne from '../views/ExploreOne'
import UploadVariants from '../views/UploadVariants'
import Activity from '../views/Activity'
import UpcomingProjects from '../views/UpcomingProjects'
import Login from '../views/Login'
import Signup from '../views/Signup'
import ForgetPassword from '../views/ForgetPassword'
import PrivacyPolicy from '../views/PrivacyPolicy'
import Products from '../views/Products'
import Ranking from '../views/Ranking'
import NotFound from '../views/NotFound'
import Support from '../views/Support'
import TermsCondition from '../views/TermsCondition'
import ComingSoon from '../views/ComingSoon'
import Maintenance from '../views/Maintenance'
import VerifyProduct from '../views/VerifyProduct.vue'
import viewBalance from '../views/ViewBalance.vue'
import Activate from '../views/Activate.vue'
import VerifyPurchase from '../views/VerifyPurchase.vue'
import CreateCollection from '../views/CreateCollection.vue'
import CollectionDetails from '../views/CollectionDetails.vue'
import VerifyCollection from '../views/VerifyCollection.vue'
const routes = [
    {
        path: '/',
        name: 'HomePageOne',
        component: HomePageOne,
        meta: {
            title: 'Nuron - NFT Marketplace Template',
        },
    },
    {
        path: '/create',
        name: 'CreateProduct',
        component: CreateProduct,
        meta: {
            title: 'Create || Sirius - NFT Marketplace',
        },
    },
    {
        path: '/addnft',
        name: 'AddNft',
        component: AddNft,
        meta: {
            title: 'Add to collection || Sirius - NFT Marketplace',
        },
    },
    {
        path: '/product/:id',
        name: 'ProductDetails',
        props: true,
        component: ProductDetails,
        meta: {
            title: 'Product Details || Sirius - NFT Marketplace',
        },
    },
    {
        path: '/product/verify/:id',
        name: 'VerifyProduct',
        props: true,
        component: VerifyProduct,
        meta: {
            title: 'Verify Product || Sirius - NFT Marketplace',
        },
    },
    {
        path: '/purchase/:id',
        name: 'VerifyPurchase',
        props: true,
        component: VerifyPurchase,
        meta: {
            title: 'Verify Product || Sirius - NFT Marketplace',
        },
    },
    {
        path: '/connect',
        name: 'Connect',
        component: Connect,
        meta: {
            title: 'Connect || Sirius - NFT Marketplace',
        },
    },
    {
        path: '/collection',
        name: 'Collection',
        component: Collection,
        meta: {
            title: 'Collection || Sirius - NFT Marketplace',
        },
    },
    {
        path: '/collection/:id',
        name: 'Collection Details',
        component: CollectionDetails,
        props:true,
        meta: {
            title: 'Collection Details || Sirius - NFT Marketplace',
        },
    },
    {
        path: '/collection/verify/:id',
        name: 'Verify Collection',
        component: VerifyCollection,
        props:true,
        meta: {
            title: 'Verify Collection || Sirius - NFT Marketplace',
        },
    },
    {
        path: '/create-collection',
        name: 'createCollection',
        component: CreateCollection,
        meta: {
            title: 'Create Collection || Sirius - NFT Marketplace',
        },
    },
    {
        path: '/creator',
        name: 'Creators',
        component: Creators,
        meta: {
            title: 'Collection || Sirius - NFT Marketplace',
        },
    },
    {
        path: '/profile/:publicKey',
        name: 'AuthorDetails',
        props: true,
        component: AuthorDetails,
        meta: {
            title: 'Author Details || Sirius - NFT Marketplace',
        },
    },
    {
        path: '/edit-profile',
        name: 'EditProfilePage',
        component: EditProfilePage,
        meta: {
            title: 'Edit Profile || Sirius - NFT Marketplace',
        },
    },
    {
        path: '/about',
        name: 'About',
        component: About,
        meta: {
            title: 'About || Sirius - NFT Marketplace',
        },
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact,
        meta: {
            title: 'Contact || Sirius - NFT Marketplace',
        },
    },
    {
        path: '/category/:slug',
        name: 'BlogCategoryPosts',
        component: BlogCategoryPosts,
        meta: {
            title: 'Blog Category List || Sirius - NFT Marketplace',
        },
    },
    {
        path: '/tag/:slug',
        name: 'BlogTagPosts',
        component: BlogTagPosts,
        meta: {
            title: 'Blog Tag List || Sirius - NFT Marketplace',
        },
    },
    {
        path: '/blog-details/:id',
        name: 'BlogDetails',
        component: BlogDetails,
        meta: {
            title: 'Blog Details || Sirius - NFT Marketplace',
        },
    },
    {
        path: '/blog',
        name: 'BlogSingleColumn',
        component: BlogSingleColumn,
        meta: {
            title: 'Blog Single Column || Sirius - NFT Marketplace',
        },
    },
    {
        path: '/explore',
        name: 'ExploreOne',
        component: ExploreOne,
        meta: {
            title: 'Explore Filter || Sirius - NFT Marketplace',
        },
    },
    {
        path: '/upload-variants',
        name: 'UploadVariants',
        component: UploadVariants,
        meta: {
            title: 'Upload Variants || Nuron - NFT Marketplace Template',
        },
    },
    {
        path: '/activity',
        name: 'Activity',
        component: Activity,
        meta: {
            title: 'Activity || Nuron - NFT Marketplace Template',
        },
    },
    {
        path: '/upcoming-projects',
        name: 'UpcomingProjects',
        component: UpcomingProjects,
        meta: {
            title: 'Upcoming Projects || Nuron - NFT Marketplace Template',
        },
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            title: 'Login || Sirius - NFT Marketplace',
        },
    },
    {
        path: '/sign-up',
        name: 'Signup',
        component: Signup,
        meta: {
            title: 'Signup || Sirius - NFT Marketplace',
        },
    },
    {
        path: '/forget',
        name: 'ForgetPassword',
        component: ForgetPassword,
        meta: {
            title: 'Forget Password || Sirius - NFT Marketplace',
        },
    },
    {
        path: '/privacy-policy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy,
        meta: {
            title: 'Privacy Policy || Nuron - NFT Marketplace Template',
        },
    },
    {
        path: '/product',
        name: 'Products',
        component: Products,
        meta: {
            title: 'Product || Sirius - NFT Marketplace',
        },
    },
    
    {
        path: '/ranking',
        name: 'Ranking',
        component: Ranking,
        meta: {
            title: 'Ranking || Nuron - NFT Marketplace Template',
        },
    },
    {
        path: '/404',
        name: 'NotFound',
        component: NotFound,
        meta: {
            title: '404 || Sirius - NFT Marketplace',
        },
    },
    {
        path: '/support',
        name: 'Support',
        component: Support,
        meta: {
            title: 'Ranking || Nuron - NFT Marketplace Template',
        },
    },
    {
        path: '/terms-condition',
        name: 'TermsCondition',
        component: TermsCondition,
        meta: {
            title: 'Terms & Condition || Nuron - NFT Marketplace Template',
        },
    },
    {
        path: '/coming-soon',
        name: 'ComingSoon',
        component: ComingSoon,
        meta: {
            title: 'Coming Soon || Nuron - NFT Marketplace Template',
        },
    },
    {
        path: '/maintenance',
        name: 'Maintenance',
        component: Maintenance,
        meta: {
            title: 'Maintenance || Nuron - NFT Marketplace Template',
        },
    },
    {
        path: '/viewBalance',
        name: 'ViewBalance',
        component: viewBalance,
        meta: {
            title: 'View Balance || Sirius - NFT Marketplace',
        },
    },
    {
        path: '/activate',
        name: 'Activate',
        component: Activate,
        meta: {
            title: 'Activate || Sirius - NFT Marketplace',
        },
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if (!to.matched.length) {
        next('/404');
        return
    }
    document.title = to.meta.title;
    next();
    window.scrollTo(0, 0)
});

export default router
