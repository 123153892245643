import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import 'animate.css'
import './assets/css/feature.css'
import './assets/scss/style.scss'
import 'bootstrap'
import '../public/config/config.json'
import { isConnected, loginState } from './auth/auth'
import mitt from 'mitt';                  // Import mitt
import axios from 'axios'
import ToastService from 'primevue/toastservice';
import PrimeVue from 'primevue/config';
import 'primeicons/primeicons.css' 
import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import {backend} from "./store/storeUrl"

const emitter = mitt();                   // Initialize mitt

let token = sessionStorage.getItem('accessToken')
if(token){
  axios.get(`${backend.url}/auth/profile`,{
      headers: {
        Authorization: "Bearer " + token
      }
  }).then((res)=>{
      if(res.status === 200){
        isConnected.value = true
        loginState.value = true
      }else{
          loginState.value = false
          sessionStorage.clear()
          isConnected.value = false
          emitter.emit("reconnect")
      }
    }).catch(e=>{
      loginState.value = false
      sessionStorage.clear()

    })
}
const app = createApp(App);
app.use(ToastService);
app.use(PrimeVue);
app.config.globalProperties.emitter = emitter;
app.use(router)         
app.mount('#app');

// createApp(App).use(router).mount('#app')
