<template>
    <router-link :to="{ name: 'ProductDetails', params: { id: productDate.nft.id }}">
    <div :class="`product-style-one ${productStyleClass}`">
        <div class="card-thumbnail image-div">
            <div class="image-contain">
                <router-link :to="{ name: 'ProductDetails', params: { id: productDate.nft.id }}">
                <img :src="`${url}/${productDate.nft.file.s3Uri}`" :alt="productDate.name" @load="$emit('handleImageLoad')">
            </router-link>
            </div>
            <router-link v-if="showPlaceBid" :to="`/product/${productDate.nft.id}`" class="btn btn-primary">
                Place Bid
            </router-link>
        </div>
        <div class="product-share-wrapper">
            <div class="profile-share">
                <!-- <router-link v-for="(author, index) in productDate"
                             :key="`author-${index}`"
                             :to="`/author/${author.owner.username}`"
                             class="avatar"
                             :data-tooltip="author.owner.username"> -->
                    <!-- <img :src="author.image" :alt="author.name"> -->
                <!-- </router-link> -->
                <!-- <router-link to="#" class="more-author-text" v-if="showPlaceBid">
                    {{ productDate.minPriceLower }}+ Place Bit.
                </router-link> -->
            </div>
            <div class="share-btn share-btn-activation dropdown">
                <button class="icon" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="feather-more-horizontal"/>
                </button>
                <div class="share-btn-setting dropdown-menu dropdown-menu-end">
                    <button type="button"
                            class="btn-setting-text share-text"
                            data-bs-toggle="modal"
                            data-bs-target="#shareModal">
                        Share
                    </button>
                    <button type="button"
                            class="btn-setting-text report-text"
                            data-bs-toggle="modal"
                            data-bs-target="#reportModal">
                        Report
                    </button>
                </div>
            </div>
        </div>
        <router-link :to="`/product/${productDate.nft.id}`">
            <span class="product-name">{{ productDate.nft.name }}</span>
        </router-link>
        <div class="price">
            <div class="logo-thumbnail logo-custom-css">
                <img :src="require(`@/assets/images/logo/proximax-logo.png`)" alt="nft-logo" />
            </div>
            <h4 class="latest-bid">{{ separator(displayPrice(productDate.minPriceUpper,productDate.minPriceLower))}}</h4>
        </div>
        <router-link v-if="displayBuy" :to="{ name: 'ProductDetails', params: { id: productDate.nft.id }}">
            <button class="btn btn-primary-alta">Buy Nft</button>
        </router-link>
        <!-- <div class="bid-react-area">
            <div class="last-bid">{{ productDate.minPriceUpper }}</div>
            <div class="react-area">
                <i class="feather-heart"/>
                <span class="number">{{ productDate.reacted }}</span>
            </div>
        </div> -->
    </div>
</router-link>
</template>
<script setup>
import { backend } from '../../store/storeUrl';
import {ref} from 'vue'


const url = backend.imageUrl
const separator = (numb) => {
    var str = numb.toString().split(".");
    str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return str.join(".");
    }

    const displayPrice = (upper, lower)=>{
        if(!lower){
            return upper
        }
        return parseFloat(upper.toString() + "." + lower.toString())
    }
</script>

<script>
    import Countdown from '@/components/product/Countdown';
import { ref } from 'vue';

    export default {
        name: 'ProductCard',
        components: {Countdown},
        props: {
            productDate: {},
            productStyleClass: {
                type: String
            },
            showPlaceBid: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return { 
                nftDetails: this.productDate ,
                displayBuy: true
            }
        },
        watch: {
            '$route': function (to, from) {
                this.closeModal()
            }
        },
        methods: {
            closeModal() {
                const modals = document.querySelectorAll('.modal-backdrop');

                if (modals.length) {
                    modals.forEach(item => {
                        item.remove();
                    })
                }
            }
        },
        mounted(){
            let isUser = this.nftDetails.owner.walletPublicKey == sessionStorage.getItem('userPublicKey')
            if(isUser){
                this.displayBuy = false
            }
        }
    }
</script>

<style scoped>
    .price{
        margin: 20px 0px 0px 0px;
        display: flex;
    }
    .image-div{
        object-fit: contain;
    }

    .image-contain{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 229px;
    }
</style>