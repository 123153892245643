<template>
    <Loading v-model:active="loading" :can-cancel="false"
        :is-full-page="true" />
<div class="container">
    <div class="form-group">
        <label for="exampleInputEmail1">Input Price</label>
        <NumberInput v-model="price" :decimal="decimal"/>
    </div>
    <div class="form-group">
        <!--Start-->
        <div class="inner">
                <div class="filter-select-option">
                    <label class="filter-leble">Currency</label>
                    <select v-model="selected" style="border-color:white; color: white; height: 50px; font-size: 20px;">
                        <option disabled value="">Please Select</option>
                        <option v-for="curr in assetName" :value="curr.assetName">{{curr.assetName}}</option>
                    </select>
                </div>
            </div>
        <!--End-->
    </div>
    <button @click="editListing()" class="btn btn-primary">Submit</button>
</div>
</template>
<script setup>
import NumberInput from '../input/NumberInput.vue';
import { ref , getCurrentInstance, watch} from 'vue';
import { store } from '../../store/store';
import {backend} from '../../store/storeUrl'
import axios from 'axios';
import { useToast } from 'primevue/usetoast';
import {MosaicHttp, MosaicId} from 'tsjs-xpx-chain-sdk'
import { useRouter } from 'vue-router';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

  const props = defineProps({
      nftId: String
  })
  
  const price = ref('')
  const decimal = ref(store.xpx.divisibility)
  const data = ref({})
  const loading = ref(false)
  const token= ref('')
  const nft = ref(null)
  const nftListed = ref([])
  const foundListed = ref(null)
  const toast = useToast()
  const id = ref('')
  const selected = ref('')
  const assetName = ref([])
  const currency = ref([])
  const internalInstance = getCurrentInstance()
    const emitter = internalInstance.appContext.config.globalProperties.emitter
  token.value = sessionStorage.getItem('accessToken')
  const publicKey = ref('')
  publicKey.value = sessionStorage.getItem("userPublicKey")
  const router = useRouter()
  const config = {
      headers: {
          authorization: `Bearer ${token.value}`
      }
  }
  
  // const getProduct = (nftId) => {
  //           return ownNFT.value.find(item => item.id == nftId);
  //     }
  
  // getProduct(props.nftId)
  const getListId = (nftId) => {
        return nftListed.value.find(item => item.nft.id == nftId)
    }
  
//   

  const editListing = async()=>{
    loading.value = true
    if(!price.value){
        toast.add({severity:'error', summary: "Must Input Price", detail:"Must Input Price", group: 'br', life: 3000})
        loading.value = false
        return
    }
    if(decimal.value === 0){
          decimal.value = null
    }
    let [upper,lower] = price.value.split('.')
    if(!lower || parseFloat(lower) == 0){
        lower = null
    }
    data.value = {
          listingId: id.value,
          minPriceLower: lower?parseFloat(lower):null,
          minPriceUpper: upper,
          currencyId: store.xpx.assetId
    }
    axios.post(backend.url+'/directListings/update',data.value,config).then((res)=>{
        toast.add({severity:'success', summary: "Listed NFT Updated", detail: "", group: 'br', life: 3000});
        document.querySelector('#uploadModal5').classList.remove('show');
        document.querySelector('body').classList.remove('modal-open');
        const mdbackdrop = document.querySelector('.modal-backdrop');
        if (mdbackdrop){
            mdbackdrop.classList.remove('modal-backdrop', 'show');
        }
        loading.value = false
        router.push("/profile/" + publicKey.value)
    }).catch(e=>{
        toast.add({severity:'error', summary: "error", detail:"error", group: 'br', life: 3000})
    })
  }

  const pop = async() =>{
    await axios.get(`https://nftm-backend.proximaxtest.com/nft/id/${props.nftId}`).then((res)=>{
            nft.value = res.data
            axios.get(`https://nftm-backend.proximaxtest.com/directListings`).then((res)=>{
                nftListed.value = res.data
                foundListed.value = getListId(props.id) ?? getListId(props.nftId)
                id.value = foundListed.value.id
            }).catch(e=>{
                toast.add({severity:'error', summary: "error", detail:"error", group: 'br', life: 3000})
            })
        })
  }

  const getCurrency = () => {
    axios.get(backend.url+'/currency').then((res)=>{
        currency.value = res.data
        currency.value.map(async(index)=>{
        let mosaicHttp = new MosaicHttp(store.testApiEndPoint)
        let res = await mosaicHttp.getMosaicsNames([new MosaicId(index.assetId)]).toPromise()
        res.map((index)=>{
            let count = 0
            assetName.value.push({assetName:index.names[count].name})
            count +=1
        })
    })  
    })
}

emitter.on("edit clicked",()=>{
    pop()
    getCurrency()
})
</script>
  <style scoped>
      .container{
          padding: 20px;
          background: #000;
      }
  </style>