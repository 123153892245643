<template>
    <ul class="mainmenu">
        <li v-for="(item, index) in navItems"
            :class="[item.hasDropdown ? 'has-droupdown has-menu-child-item' : '', item.megamenu ? 'with-megamenu' : '']"
            :key="`nav-item-${index}`">
            <router-link :to="item.link" class="nav-link its_new"  v-if="!item.hide">
                {{ item.title }}
            </router-link>
            <ul v-if="item.hasDropdown" class="submenu">
                <li v-for="(subMenuItem, subMenuIndex) in item.dropdownItems"
                    :key="`sub-menu-item-${subMenuIndex}`">
                    <router-link :to="subMenuItem.link" :class="subMenuItem.class" >
                        {{ subMenuItem.title }}
                        <i v-if="subMenuItem.class !== 'live-expo'" :class="`feather-${subMenuItem.icon}`"/>
                    </router-link>
                </li>
            </ul>
            <div v-if="item.megamenu" class="rn-megamenu">
                <div class="wrapper">
                    <div class="row row--0">
                        <div v-for="(megamenuItem, megamenuIndex) in item.megaMenuItems"
                             :key="`mega-menu-item-${megamenuIndex}`"
                             class="col-lg-3 single-mega-item">
                            <ul class="mega-menu-item">
                                <li v-for="(megamenuSubItem, megamenuSubIndex) in megamenuItem.items"
                                    :key="`mega-submenu-item-${megamenuSubIndex}`">
                                    <router-link :to="megamenuSubItem.link" v-if="!megamenuSubItem.hide">
                                        {{ megamenuSubItem.title }} <i :class="`feather-${megamenuSubItem.icon}`"/>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    </ul>
</template>
<script setup>
    import { isConnected, loginState } from '../../../auth/auth'
    import { computed } from 'vue';

    const navItems = computed(()=>{
        return [
                {
                    link: '/',
                    title: 'Home',
                    hasDropdown: false,
                    megamenu: false,
                    hide:false
                },
                {
                    link: '/about',
                    title: 'About',
                    hasDropdown: false,
                    megamenu: false,
                    hide:false
                },
                {
                    link: '/explore',
                    title: 'Explore',
                    hasDropdown: false,
                    megamenu: false,
                    hide:false
                },
                {
                    link: '#',
                    title: 'Pages',
                    hasDropdown: false,
                    megamenu: true,
                    hide: loginState.value ? false : true,
                    megaMenuItems: [
                        {
                            items: [
                                // {link: '/activity', title: 'Activity', icon: 'activity',hide: false},
                                {link: '/viewBalance', title: 'Balance', icon: 'pocket', hide: loginState.value ? false : true},



                            ]
                        },
                        {
                            items: [
                                // {link: '/creator', title: 'Creators', icon: 'users',hide: false},
                                {
                                    link: '/create-collection',
                                    title: 'Create Collection',
                                    icon: 'file',
                                    hasDropdown: false,
                                    megamenu: false,
                                    hide: loginState.value ? false : true
                                },


                            ]
                        },
                        {
                            items: [
                                {link: '/create', title: 'Create NFT', icon: 'file-plus',hide: loginState.value ? false : true}, 
                                {link: '/connect', title: 'Connect to Wallet', icon: 'pocket',hide: loginState.value ? true : false},
     


                            ]
                        },
                        {
                            items: [
                                {link: '/sign-up', title: 'Registration', icon: 'user-plus',hide: loginState.value ? true :isConnected.value?false: true},
                                {link: '/edit-profile', title: 'Edit Profile', icon: 'user',hide: loginState.value ? false : true},
                            ]
                        }
                    ]
                },

                
                {   link: {name: "AuthorDetails", 
                    params: { publicKey: sessionStorage.getItem('userPublicKey') ? sessionStorage.getItem('userPublicKey') : "1"}},
                    title: 'Profile',
                    icon: 'user', 
                    hide: loginState.value ? false : true
                },
            ]
    })
</script>
<script>


export default {
    name: 'Nav',
    
   
}
</script>