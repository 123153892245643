<template>
    <layout>
        <div v-if="bannerPic" :style="{backgroundImage: `url(${bannerPic})` }" class="rn-author-bg-area bg_image ptb--150">
            <div class="container">
                <div class="row"></div>
            </div>
        </div>
        <div v-else class="rn-author-bg-area bg_image--9 bg_image ptb--150">
            <div class="container">
                <div class="row"></div>
            </div>
        </div>

        <div class="rn-author-area mb--30 mt_dec--120">
            <div class="container">
                <div class="row padding-tb-50 align-items-center d-flex">
                    <div class="col-lg-12">
                        <div class="author-wrapper">
                            <div class="author-inner">
                                <div class="user-thumbnail">
                                    <img  v-if="profilePic" :src="profilePic">
                                    <img v-else src="../assets/images/client/blankPfp.png">
                                </div>
                                <div class="rn-author-info-content">
                                    <h4 class="title">{{ username }}</h4>
                                    <router-link to="#" class="social-follw">
                                        <i class="feather-twitter"/>
                                        <span class="user-name">{{username}}</span>
                                    </router-link>
                                    <div class="follow-area">
                                        <div class="follow followers">
                                            <span>
                                                186k <router-link to="#" class="color-body">followers</router-link>
                                            </span>
                                        </div>
                                        <div class="follow following">
                                            <span>
                                                156 <router-link to="#" class="color-body">following</router-link>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="author-button-area">
                                        <span class="btn at-follw follow-button">
                                            <i class="feather-user-plus" data-feather="user-plus"/> Follow
                                        </span>
                                        <span class="btn at-follw share-button"
                                              data-bs-toggle="modal"
                                              data-bs-target="#shareModal">
                                            <i class="feather-share-2"/>
                                        </span>
                                        <div class="count at-follw d-inline-flex align-items-center" v-if="stateNum===2">
                                            <div class="share-btn share-btn-activation dropdown">
                                                <button class="icon" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i class="feather-more-horizontal"/>
                                                </button>
                                                <div class="share-btn-setting dropdown-menu dropdown-menu-end">
                                                    <button type="button"
                                                            class="btn-setting-text report-text"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#reportModal">
                                                        Report
                                                    </button>
                                                    <button type="button" class="btn-setting-text report-text" >
                                                        Claim Ownership
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <router-link to="/edit-profile" class="btn at-follw follow-button edit-btn" v-if="stateNum===2">
                                            <i class="feather-edit"/>
                                        </router-link>
                                        <button @click="activate()" type="button" class="btn at-follw follow-button" v-if="stateNum===0">
                                                Activate
                                        </button>
                                        <div class="activation" v-if="stateNum === 1">
                                                Activation in process
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="rn-authore-profile-area">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="tab-wrapper-one">
                            <nav class="tab-button-one">
                                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                    <button class="nav-link"
                                            id="nav-home-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#nav-home"
                                            type="button" role="tab"
                                            aria-controls="nav-home"
                                            aria-selected="false">
                                        On Sale
                                    </button>
                                    <button class="nav-link active"
                                            id="nav-profile-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#nav-profile"
                                            type="button"
                                            role="tab"
                                            aria-controls="nav-profile"
                                            aria-selected="true">
                                        Owned
                                    </button>
                                    <button class="nav-link"
                                            id="nav-contact-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#nav-contact"
                                            type="button"
                                            role="tab"
                                            aria-controls="nav-contact"
                                            aria-selected="false">
                                        Created
                                    </button>
                                    <!-- <button class="nav-link active"
                                            id="nav-collections-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#nav-collections"
                                            type="button" role="tab"
                                            aria-controls="nav-collections"
                                            aria-selected="true">
                                        Collections
                                    </button> -->
                                    <button class="nav-link"
                                            id="nav-collection-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#nav-collection"
                                            type="button" role="tab"
                                            aria-controls="nav-collection"
                                            aria-selected="false">
                                        Collections
                                    </button>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>

                <div class="tab-content rn-bid-content" id="nav-tabContent">
                    <div class="tab-pane row g-5 d-flex fade"
                         id="nav-home"
                         role="tabpanel"
                         aria-labelledby="nav-home-tab">
                        <div class="col-5 col-lg-4 col-md-6 col-sm-6 col-12"
                             v-for="(product, index) in listNft"
                             :key="`product-item-${index}`">
                            <ProductCardList
                                :product-date="product"
                                product-style-class="no-overlay with-placeBid"
                                :show-place-bid="false"
                            />
                        </div>
                    </div>
                    <div class="tab-pane row g-5 d-flex fade show active"
                         id="nav-profile"
                         role="tabpanel"
                         aria-labelledby="nav-profile-tab">
                        <div class="col-5 col-lg-4 col-md-6 col-sm-6 col-12"
                             v-for="(product, index) in nft"
                             :key="`product-item-${index}`">
                            <ProductCardOwn
                                :product-date="product"
                                product-style-class="no-overlay with-placeBid"
                                :show-place-bid="false"
                            />
                        </div>
                    </div>
                
                <div class="tab-pane row g-5 d-flex fade"
                        id="nav-contact"
                        role="tabpanel"
                        aria-labelledby="nav-contact-tab">
                    <div class="col-5 col-lg-4 col-md-6 col-sm-6 col-12"
                            v-for="(product, index) in nftCreated"
                            :key="`product-item-${index}`">
                        <!--Product card created-->
                        <ProductCardOwn
                            :product-date="product"
                            product-style-class="no-overlay with-placeBid"
                            :show-place-bid="false"
                        />
                    </div>
                </div>

                <div class="tab-pane row g-5 d-flex fade"
                        id="nav-collection"
                        role="tabpanel"
                        aria-labelledby="nav-collection-tab">
                    <div class="col-5 col-lg-4 col-md-6 col-sm-6 col-12"
                    v-for="(product, index) in collections"
                    :key="`product-item-${index}`">
                        <ProductCardCollection
                            :collection="product"
                            product-style-class="no-overlay with-placeBid"
                            :show-place-bid="false"
                        />
                    </div>
                </div>
            </div>
                    <!-- <div class="tab-pane row g-5 d-flex fade"
                         id="nav-liked"
                         role="tabpanel"
                         aria-labelledby="nav-contact-tab">
                        <div class="col-5 col-lg-4 col-md-6 col-sm-6 col-12"
                             v-for="(product, index) in author.products.liked"
                             :key="`product-item-${index}`">
                            <product-card
                                :product-date="product"
                                product-style-class="no-overlay with-placeBid"
                                :show-place-bid="true"
                            />
                        </div>
                    </div>
                </div>-->
            </div>
        </div>

        <share-modal/>

        <report-modal/>
    </layout>
</template>
<script setup>
import ProductCardOwn from "../components/product/ProductCardOwn.vue"
import ProductCardList from "../components/product/ProductCardList.vue"
import ProductCardCollection from "../components/product/ProductCardCollection.vue";
import { ref,onMounted } from "vue"
import { useToast } from "primevue/usetoast";
import axios from "axios"
import { useRouter } from "vue-router";


const props = defineProps({
    publicKey: String
})
const router = useRouter()
const nft = ref([])
const listNft = ref([])
const nftCreated = ref([])
const toast = useToast()
const username = ref('')
const escrowKey = ref('')
const state = ref(true)
const profilePic = ref(null)
const bannerPic = ref(null)
const stateNum = ref(null)
const collections = ref([])
try{
    PublicAccount.createFromPublicKey(props.publicKey,store.networkType)
}catch{(e)=>{
        router.push("/404")
    }
}

const getUser = async() => {
    try {
        let res = await axios.get(`${backend.url}/users/owner/${props.publicKey}`)
        profilePic.value = res.data.profilePic == null? null:`${store.imageUrl}/${res.data.profilePic.s3Uri}`
        bannerPic.value = res.data.bannerPic == null? null:`${store.imageUrl}/${res.data.bannerPic.s3Uri}`
        username.value = res.data.username
        escrowKey.value = res.data.escrowPublicKey
        if(props.publicKey != sessionStorage.getItem("userPublicKey") && !loginState.value){
            return
        }
        if(!res.data){
            router.push("/404")
        }
        if(res.data.activation === 0){
            stateNum.value = 0
        }else if(res.data.activation === 1){
            stateNum.value = 1
        }else{
            stateNum.value = 2
        }
        createdNft()
    } catch (e) {
        toast.add({severity:'error', summary: e.response.statusText, detail:e.response.data.message, group: 'br', life: 3000});
        router.push("/404")
    }
}

const createdNft = () => {
    axios.get(`${backend.url}/nft/creator/${escrowKey.value}`).then((res)=>{
        nftCreated.value = res.data
    }).catch(e=>{
        toast.add({severity:'error', summary: e.response.statusText, detail:e.response.data.message, group: 'br', life: 3000});
    })
}

const viewNft = () => {
    axios.get(`${backend.url}/nft/owner/${props.publicKey}`).then((res)=>{
        nft.value = res.data
    }).catch(e=>{
            toast.add({severity:'error', summary: e.response.statusText, detail:e.response.data.message, group: 'br', life: 3000});
            router.push("/404")
    })
}

const viewCollections = () => {
    axios.get(`${backend.url}/collection/owner/${props.publicKey}`).then((res)=>{
        collections.value = res.data
    }).catch(e=>{
            toast.add({severity:'error', summary: e.response.statusText, detail:e.response.data.message, group: 'br', life: 3000});
            router.push("/404")
    })
}

const viewListedNft = () =>{
    axios.get(`${backend.url}/directListings/owner/${props.publicKey}`).then((res)=>{
        listNft.value = res.data
    }).catch(e=>{
        toast.add({severity:'error', summary: e.response.statusText, detail:e.response.data.message, group: 'br', life: 3000});
    })

}
//http://localhost:8080/profile/CE8CD495D14DD0520FF3950996AC580C1E98139FB058055DA1EE2331065F852A



const activate = () => {
    router.push("/activate")
}

onMounted(async() => {
    
    await getUser()
    viewNft()
    viewListedNft() 
    viewCollections()
    if(props.publicKey != sessionStorage.getItem("userPublicKey") || !loginState.value){
        stateNum.value = null
    }
    
})

//CE8CD495D14DD0520FF3950996AC580C1E98139FB058055DA1EE2331065F852A

</script>

<script>
    import ShareModal from '@/components/modal/ShareModal'
    import ReportModal from '@/components/modal/ReportModal'
    import Layout from '@/components/layouts/Layout'
    import ProductCard from '@/components/product/ProductCard'
import { PublicAccount } from "tsjs-xpx-chain-sdk";
import { store } from "../store/store";
import { loginState } from "../auth/auth";
import { backend } from "../store/storeUrl";




    export default {
        name: 'AuthorDetails',
        components: {ProductCard, Layout, ReportModal, ShareModal},
        data() {
            return{}
        },
    }
</script>
<style scoped>
.activation{
    margin-left: 8px;
    margin-top: 11px;
    text-decoration: underline;

}

</style>